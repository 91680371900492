import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import ApiService from "../../services/api.service";
import HeroAutoPlay from './components/HeroAutoPlay';

const HeaderAutoPlay = (props) => {
    const [slideToShow, setSlideToShow] = useState([]);
    
    useEffect(()=>{
        const fetchData = async() =>{
            await ApiService.getSlider().then((res) => {
                setSlideToShow(res.data);
            });
        }

        fetchData();
      }, []);

    return (
        <Content className="header">
            <HeroAutoPlay images={slideToShow.slider}/>
        </Content>
    )
}

const Content = styled.header`
overflow: hidden;
height: 100vh;
position: relative;
`;


export default HeaderAutoPlay

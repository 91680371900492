import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ApiService from '../../../../../services/api.service'
import ParaXL from '../../../../Texts/Para/ParaXL/ParaXL'
import TitleLImage100vw from './components/TitleLImage100vw'


const MainContent = () => {
    const [projectsToShow, setProjectsToShow] = useState([]);
    const [data, setData] = useState([]);
    const [visible, SetVisible] = useState(6)


      useEffect(async() => {
        await ApiService.getHomeData().then((res) => {
          setData(res.data);
          setProjectsToShow(res.data.block_flex);
          //console.log(res.data)
        })

        //loopThroughProjects(count);
      }, []);

      const handleShowMoreProjects = () => {
        SetVisible((prevCount) => prevCount + 9);
        //loopThroughProjects(count);
      };

    return (
        <Content className="homepage">
            <ParaXL customClass="firstPara" text= {data.texte ? data.texte : ""}/>
            <Space></Space>

            <TitleLImage100vw projectsToRender={projectsToShow.slice(0, visible)} />

            <SeeMore className={
              visible >= projectsToShow.length
                ? `see-more hidden`
                : `see-more`
                }>
              <button className="text text--title text--light-grey uppercase"
              onClick={handleShowMoreProjects}
              >
                Load More ↓
              </button>
            </SeeMore>

        </Content>
    )
}

const Content = styled.main`
`;

const Space= styled.div`
height: 80px;
@media (min-width: 576px) {
  height: 200px;
 }
width: 100%;
`;

const SeeMore = styled.div`

.uppercase{
  background-color: #ededed;
    margin-left: 4px;
    color: black;
    font-size: 20px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 200px;
}
.hidden {
  display: none;
  cursor: initial;
}
`;

export default MainContent

import $ from 'jquery';
import React, { useEffect } from "react";
import styled from "styled-components";

const HeroImage = ({ image }) => {

  useEffect(()=>{
    class Parallax {
			constructor(object) {
				this.object = object;
				this.offset = this.object.offset().top;
				this.object.css("background-position-y", `${this.offset + $(window).scrollTop() / 4}px`)
			} parallax() {
				this.object.css("background-position-y", `${this.offset + $(window).scrollTop() / 4}px`)
			}
		}

		var parallax = new Parallax($('.parallax'));
		$(window).scroll(function () {
			parallax.parallax()
		})

  })
  return (
      <Content className="hero">
        <div className="hero__image img parallax" style={{ backgroundImage: `url(${image})` }}>
           <img className="cover" style={{ opacity: 0 }} src={image} alt="" />
        </div>
      </Content>
  );
};

const Content = styled.div`
  .hero__image {
    .cover {
      object-fit: cover;
      object-position: 50% 50%;
    }
    .parallax-inner {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100vh;
      @media screen and (max-width: 480px){
        height: 100%;
      }
    }
  }
`;
export default HeroImage;

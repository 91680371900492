import React from 'react'
import styled from 'styled-components'

const ParaS = ({text}) => {
    return (
        <Content className="col-1__text para--s container">
            <p className="text text--small">{text}</p>
        </Content>
    )
}


const Content = styled.main`
text-align: left;
p {
    grid-column: col-start 1 / span 4;

    @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
        grid-column: col-start 2 / span 6;
    }
    @media (min-width: 768px) { 
        grid-column: col-start 2 / span 10;
    } 
}
`;

export default ParaS

import React from 'react';
import styled from 'styled-components';

const Cols3Left2 = ({selection}) => {
    return (
        <Content>
            <div className="cols3--left container">
                {selection ? selection.map((selection, index) => (
                  <div key={index} className={'cols-'+ index + ' container'}>
                  <h2 key={index} className={'col-'+ index + '__title text text--small'}>{(selection.type).toUpperCase()}</h2>
                  <ul key={index} className={'col-'+ index + '__text text text--small'}>
                      {selection && selection.choix && selection.choix[0] && selection.choix.map((choix, index) => (
                          <li key={index} className={'li-'+ index}>{choix.post_title}</li>
                      ))}
                  </ul>
              </div>
                )): ''}
            </div>
        </Content>
    )
}


const Content = styled.div`
.cols3--left {
    padding: 0;
    .cols-0 {
        width: 100%;
        grid-row: 1;

        .col-0__title {
            grid-column: col-start 1 / span 1;
            grid-row: 1;

            @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
                grid-column: col-start 1 / span 1;
            }
            @media (min-width: 768px) { 
                grid-column: col-start 1 / span 1;
            } 
        }

        .col-0__text {
            grid-column: col-start 2 / span 3;
            grid-row: 1;

            @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
                grid-column: col-start 2 / span 3;
            }
            @media (min-width: 768px) { 
                grid-column: col-start 2 / span 3;
            } 
        }
    }

    .cols-1 {
        width: 100%;
        grid-row: 2;
        @media (min-width: 768px) { 
            grid-row: 1;
        } 

        .col-1__title {
            grid-column: col-start 1 / span 1;
            grid-row: 1;

            @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
                grid-column: col-start 1 / span 1;
            }
            @media (min-width: 768px) { 
                grid-column: col-start 5 / span 1;
            } 
        }
      
        .col-1__text {
            grid-column: col-start 2 / span 3;
            grid-row: 1;

            @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
                grid-column: col-start 2 / span 3;
            }
            @media (min-width: 768px) { 
                grid-column: col-start 6 / span 3;
            } 
        }
    }

    .cols-2 {
        width: 100%;
        grid-row: 3;
        @media (min-width: 768px) { 
            grid-row: 1;
        } 

        .col-2__title {
            grid-column: col-start 1 / span 1;
            grid-row: 1;
            @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
                grid-column: col-start 1 / span 1;
            }
            @media (min-width: 768px) { 
                grid-column: col-start 9 / span 1;
            } 
        }
        .col-2__text {
            grid-column: col-start 2 / span 3;
            grid-row: 1;
            @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
                grid-column: col-start 2 / span 3;
            }
            @media (min-width: 768px) { 
                grid-column: col-start 10 / span 3;
            } 
        }
      }

    }
}
`;


export default Cols3Left2

import React from 'react'
import styled from 'styled-components'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"


const HeroAutoPlay = ({images}) => {

        return (
            <Content className="hero">
                <div className="hero__slider">
                    <Carousel autoPlay infiniteLoop autoFocus={true} showThumbs={false} showStatus={false} useKeyboardArrows>
                    {images ? images.map((image) => (
                      <div>
                          <a href={image.external}>
                            <img src={image.url} alt=""/>
                          </a>
                      </div>
                    )): ''}
                    </Carousel>
                </div>
            </Content>
        )
      }
    


const Content = styled.div`
.hero__slider {
  .slide {
    img {
      height: 100vh;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  .control-dots, 
  .carousel-status {
    display: none;
  }

  .control-arrow {
    width: 50%;
  }

  .animated {
    transition: inherit !important;
    transition-duration: 0;
  }
}

button {
  &.control-arrow {
    width: 50%;

    &:hover {
      background: none !important;
    }
    &:before {
      border-left: none !important;
      border-right: none ! important;
    }
  }

  &.control-next,
  &.control-prev {
    cursor: inherit !important;
  }

}

`;

export default HeroAutoPlay

import React from 'react';
import styled from 'styled-components';

const ParaL = ({text}) => {
    return (
        <Content className="para--l container">
            <p className="text text--subtitle" dangerouslySetInnerHTML={{__html: text}}></p>
        </Content>
    )
}


const Content = styled.main`
width: 100%;
margin: 0 0 60px 0;
padding-top: 8px;
font-size: 2.1rem;
line-height :  2.1rem;
p {
    font-size: 2.1rem;
    line-height :  2.1rem;
    grid-column: col-start 1 / span 4;
    

    @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
        grid-column: col-start 2 / span 6;
    }
    @media (min-width: 768px) { 
        grid-column: col-start 2 / span 10;
    } 
    @media (max-width: 576px){
    font-size: 1.3rem;
    line-height: 1.45rem;
}
}
`;


export default ParaL


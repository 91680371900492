import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import Footer from '../../Footer/Footer';
import Menu from '../../Menu/Menu';
import MainContent from './components/MainContent/MainContent';


const Projects = () => {
    return (
      <Fragment>
        <Helmet>
            <title>PROJECTS</title>
        </Helmet>
        <Menu/>
        
        <MainContent/>
        <Footer/>
      </Fragment>
    )
}

export default Projects

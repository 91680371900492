import React from 'react'
import styled from 'styled-components'
import CarouselS from '../../../../../Carousel/CarouselS'


const CarouselProject = ({title, text, slider, option}) => {
    
    return (    
        <Content>   
            <CarouselS title={title} text={text} slide={slider} option={option}/>
        </Content>
    )
}

const Content = styled.div`
`;

export default CarouselProject


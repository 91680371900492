import React from 'react';
import styled from 'styled-components';

const ParaXL = ({text, customClass}) => {
    return (
        <Content className="para--xl container">
            <p className={"text text--title "+customClass} dangerouslySetInnerHTML={{__html: text}}></p>
        </Content>
    )
}


const Content = styled.main`
&.para--xl {
    /**margin: 20px 0*/
    text-align: center;
    @media screen and (max-width: 480px){
        padding-top:4px;
        p{
            font-size:2.2rem;
            line-height:2.1rem;
        }
        
  }
}

`;  

export default ParaXL

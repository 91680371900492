import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ApiService from "../../../../../services/api.service";
import CarouselS from "../../../../Carousel/CarouselS";
import Line from "../../../../Line/Line";
import ParaL from "../../../../Texts/Para/ParaL/ParaL";
import Image100vwCols3 from "./components/Image100vwCols3";
import TextMCols2 from "./components/TextMCols2";

const MainContent = () => {

  const [data, setData] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(async () => {
    await ApiService.getStudioData().then((res) => {
      setData(res.data);
      setContent(res.data.block_flex);
      //console.log(res);
    });
  }, []);
  
  return (
    <Content className="studio">
      {/* <ParaL text={data.texte} /> */}
      {content ? content.map((project, index) => (
        <section key={index}>
          {project.acf_fc_layout === "img_100" ? (
            <>
              <Image100vwCols3 url={project.image} selection={project.selection} legende={project.legende}/>
              <Line />
            </>
          ) : project.acf_fc_layout === "txt_s_listes" || project.acf_fc_layout === "listes"  ? (
            <>
              <TextMCols2 titre={project.titre} contenu={project.contenu} option={project.options} liste={project.selection} />
              <Line />
            </>
          ) : project.acf_fc_layout === "txt_m"  ? (
            <>
              <ParaL text={project.contenu} option={project.options} />
              <Line />
            </>
          )
          : project.acf_fc_layout === "carousel" ? (
            <>
              <CarouselS title={project.titre} slide={project.slide} option={project.options} />
              <Line />
            </>
          ) : (
            ""
          )}
        </section>
      )) : ''}
      
    </Content>
  );
};

const Content = styled.div``;

export default MainContent;

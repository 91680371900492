import React, { useEffect, useState } from 'react';
import FilterProjects from './components/FilterProjects';

const MainContent = () => {
    const [load, setLoad] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoad(true)
        }, 3000);
        

      }, []);

    return (
        <>
        {!load && 
        <div className='loader'></div>
        }
        <main className="projects-page">
            <FilterProjects/>
        </main>
        </>
    )
}

export default MainContent

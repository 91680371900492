import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Image100vwFullScreen from "../../../../../Images/Image100vwFullScreen";
import TitleXL from "../../../../../Texts/Titles/TitleXL/TitleXL";
import Video100vw from "../../../../../Videos/Video100vw";

const TitleLImage100vw = ({ projectsToRender, title }) => {
  return (
    <Content className="projects">
      {projectsToRender
        ? projectsToRender.map((project, index) =>
            project.acf_fc_layout === "blog_element" ? (
              <li key={index}>
                <TitleXL title={project.title} />
                {project.external_link ? (
                  <Link
                    target="_blank"
                    to={
                      project.external_link
                        ? { pathname: project.link.url }
                        : {
                            pathname: `/projects/${project.project_link.post_name}`,
                          }
                    }
                  >
                    {project.image ? (
                      <Image100vwFullScreen imageUrl={project.image.url} />
                    ) : (
                      ""
                    )}
                  </Link>
                ) : (
                  <Link
                    to={
                      project.external_link
                        ? { pathname: project.link.url }
                        : {
                            pathname: `/projects/${project.project_link.post_name}`,
                          }
                    }
                  >
                    {project.image ? (
                      <Image100vwFullScreen imageUrl={project.image.url} />
                    ) : (
                      ""
                    )}
                  </Link>
                )}
              </li>
            ) : project.acf_fc_layout === "blog_element_video" ? (
              <li key={index}>
                <TitleXL title={project.title} url={project.url} />
                {project.video ? (
                  <Video100vw
                    videoUrl={
                      project.external_link ? project.link : project.video.url
                    }
                  />
                ) : project.link ? (
                  <Video100vw
                    videoUrl={
                      project.external_link
                        ? project.link.url
                        : project.video.url
                    }
                  />
                ) : (
                  ""
                )}
              </li>
            ) : (
              ""
            )
          )
        : ""}
    </Content>
  );
};

const Content = styled.ul`
li:hover h2:after{
  opacity:1;
}
h2 {
  margin-top: 100px;
  margin-bottom: 0px;
  &:after {
    content:" ↗";
    opacity:0;
}
@media (max-width: 480px) {
  &:after {
    content:"";
    opacity:0;
}
 }
  @media (min-width: 576px) {
    margin-top: 100px;
    margin-bottom: 0px;
   }
   @media (max-width: 576px) {
    margin-top: 50px;
    line-height: 30px;
   }
   
  }
}
  .project__img {
    img {
      width: 100vw;
    }
  }
  
`;
export default TitleLImage100vw;

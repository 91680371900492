import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import TitleM from "../../../../../../Texts/Titles/TitleM/TitleM";
import ParaM from "../../../../../../Texts/Para/ParaM/ParaM";
import ProjectVariations from "../../../../../../Carousel/components/ProjectVariations";
import LiveInfos from "../LiveInfos";
import Slider from "react-slick";
import "react-fancybox/lib/fancybox.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

const SliderInfos = ({ title, text, slide, categorie, live, option }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [showVariations, setShowVariations] = useState(false);
  const totalIndex = slide.length;
  const [currentImg, setCurrentImg] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML = `
           jQuery(function () {
            
            $(".ril__image").click(function(){
              $(".ril-close").click();
            });

      });

    
        `;
    document.body.appendChild(s);
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: ".slider-nav",
    beforeChange: (current, next) => {
      let currentIndex = next + 1;
      setCurrentIndex(currentIndex);
    },
    afterChange: (current) => setShowVariations(false),
  };

  const handleClickImage = (e, image) => {
    e && e.preventDefault();
    setCurrentImg(image);
    setIsOpen(true);
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML = `
           jQuery(function () {
            
            $(".ril__image").click(function(){
              $(".ril-close").click();
            });

      });

    
        `;
    document.body.appendChild(s);
  };

  const handleCloseModal = (e) => {
    e && e.preventDefault();
    setIsOpen(false);
    setCurrentImg("");
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    focusOnSelect: true,
    arrows: false,
    infinite: false,
  };

  return (
    <Content className="slider__section" style={{
      backgroundColor: `${option ? option.background_color : ""}`,
      color: `${option ? option.text_color : ""}`,
    }}>
      <Carousel className="carousel--m">
        <div className="slider">
          <div className="main__col-1">
            <TitleM title={title} />
            <ParaM text={text} />
          </div>

          {isOpen && (
            <Lightbox
              mainSrc={slide[currentImg].url}
              nextSrc={slide[(currentImg + 1) % slide.length]}
              prevSrc={slide[(currentImg + slide.length - 1) % slide.length]}
              onCloseRequest={handleCloseModal}
              onMovePrevRequest={() => {
                const s = document.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                s.innerHTML = `
           jQuery(function () {
            
            $(".ril__image").click(function(){
              $(".ril-close").click();
            });

      });

    
        `;
                document.body.appendChild(s);
                setCurrentImg((currentImg + slide.length - 1) % slide.length);
              }}
              onMoveNextRequest={() => {
                const s = document.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                s.innerHTML = `
           jQuery(function () {
            
            $(".ril__image").click(function(){
              $(".ril-close").click();
            });

      });

    
        `;
                document.body.appendChild(s);
                setCurrentImg((currentImg + 1) % slide.length);
              }}
            />
          )}

          <div className="carousel">
            <div className="carousel__slider">
              <div className="slider-wrapper slider-slick">
                <Slider
                  {...settingsMain}
                  asNavFor={nav2}
                  ref={(slider) => setSlider1(slider)}
                >
                  {slide &&
                    slide[0] &&
                    slide.map((slide, index) => (
                      <div className="slick-slide slide-princ" key={slide.ID}>
                        <a onClick={(e) => handleClickImage(e, index)}>
                          <img src={`${slide.url}`} />
                        </a>
                        <label className="slick-slide-label">
                          {slide.label}
                        </label>
                      </div>
                    ))}
                </Slider>

                {showVariations ? (
                  <div className="thumbnail-slider-wrap">
                    <div className="main__col-1">
                      <TitleM title={title} />
                      <ParaM text={text} />
                    </div>
                    <Slider
                      {...settingsThumbs}
                      asNavFor={nav1}
                      ref={(slider) => setSlider2(slider)}
                    >
                      {slide &&
                        slide[0] &&
                        slide.map((slide) => (
                          <div className="" key={slide.ID}>
                            <img
                              className="slick-slide-image"
                              src={`${slide.url}`}
                            />
                          </div>
                        ))}
                    </Slider>
                    <Footer className="container">
                      <button
                        className="btn btn--close"
                        onClick={() => setShowVariations(false)}
                      >
                        <p className="text text--small uppercase">Close</p>
                      </button>
                      <div className="total">
                        <p className="text text--small uppercase">
                          {slide.length} img(s)
                        </p>
                      </div>
                    </Footer>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {
            <div className="slider__meta container">
              <button
                className="slider__meta--all text text--small uppercase"
                onClick={() => setShowVariations(true)}
                style={{
                  color: `${option ? option.text_color : ""}`,
                }}
              >
                All
              </button>
              <p style={{
                color: `${option ? option.text_color : ""}`,
              }} className="slider__meta--pag text text--small uppercase">
                {currentIndex}/{totalIndex}
              </p>
            </div>
          }
        </div>
      </Carousel>
      <LiveInfos showVariations={showVariations}  category={categorie} live={live} option={option} />
    </Content>
  );
};

const Content = styled.div``;
const Carousel = styled.div`
  .slider {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
  }
  .slider__img {
    display: block;
    margin: 0 auto;
    overflow: hidden;
    height: 500px;
    width: calc(100% - 24px);
    @media (min-width: 576px) {
      width: auto;
      height: 600px;
    }
    img {
      height: 100%;
    }
  }

  .slider__content {
    text-align: center;
    grid-column: col-start / span 12;
  }

  .slider__meta {
    button {
      text-align: left;
      grid-column: col-start / span 1;
      grid-row: 1;
    }
    z-index: 2;
    margin: 60px 0 12px 0;
    p {
      grid-row: 1;
      margin: 0 auto;
    }
  }

  .carousel__slider {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      @media (min-width: 576px) {
        width: auto;
        max-width: calc(100% - 24px);
        height: 100%;
      }
    }

    .splide__slide {
      display: flex;
      justify-content: center;
    }
  }

  .slider__nav {
    svg {
      display: none;
    }
  }

  .slider__nav--arrows {
    height: 100%;
    border-radius: 0;
    width: 120px;
    @media (min-width: 576px) {
      width: 220px;
    }
    background: none;
    &:hover {
      display: block;
    }
  }

  .splide__arrow--prev {
    left: 0;
  }
  .splide__arrow--next {
    right: 0;
  }

  @media (max-width: 575.98px) {
    .splide__slide {
      width: 100vw !important;
    }
    .splide__track {
      padding: 0 !important;
    }
  }

  button {
    &.slider__nav--next {
      cursor: url(../../assets/images/arrow-next.svg), auto;
    }
    &.slider__nav--prev {
      cursor: url(../../assets/images/arrow-prev.svg), auto;
    }
  }

  .splide__pagination {
    display: none;
  }

  .variations-page {
    background: #e4e4e4;
    min-height: 750px;

    .variations__slider {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 700px;

      .para--m {
        text-align: left;
      }
      .content {
        border-bottom: 1px solid ${({ theme }) => theme.colors.darkGrey};
      }
    }
  }
`;

const Gallery = styled.div`
  text-align: center;
`;

const Footer = styled.div`
  width: 100%;
  margin: 60px 0 0 0;
  position: absolute;
  bottom: 0;
  padding: 0 12px 12px 12px;

  .btn--close {
    grid-column: col-start 1 / span 1;
    grid-row: 1;
    text-align: left;
    padding: 0;
    z-index: 2;
  }
  .total {
    grid-row: 1;
    text-align: center;
    padding: 0;
  }
`;
export default SliderInfos;

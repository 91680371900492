import React, { Fragment, useEffect } from 'react'
import MainContent from './components/MainContent/MainContent'
import styled from 'styled-components'


const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
    return (
      <Fragment>
        <Content>
          <MainContent/>
        </Content>
      </Fragment>
    )
}


const Content = styled.main`
background: ${({ theme }) => theme.colors.lightGrey};
min-height: 100vh; 
padding-top: 12px;
`;

export default Privacy

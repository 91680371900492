import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import ApiService from "../../services/api.service";
import HeroSlider from './components/HeroSlider/HeroSlider';


const Content = styled.header`
overflow: hidden;
height: 100vh;
position: relative;
`;

const HeaderSlider = (props) => {
    const [slideToShow, setSlideToShow] = useState([]);
    const [total, setTotal] = useState(0);
    
    useEffect(async () => {
        await ApiService.getSlider("home").then((res) => {
            setSlideToShow(res.data);
            setTotal(res.data.slider.length);

        });
      }, [slideToShow]);
    return (
        <Content className="header">
            <HeroSlider images={slideToShow.slider} total={total}/>
        </Content>
    )
}

export default HeaderSlider

import React from 'react';
import styled from 'styled-components';
import TitleM from '../../../../../../../Texts/Titles/TitleM/TitleM';
import TitleThumbnail from '../../../../../../Projects/components/MainContent/components/TitleThumbnail';


const TitleThumbnailCols4 = ({projet, title,grap}) => {

    return (
        <Content className="projects__grid">
            <TitleM title={title} />
                <div className={"cols4__projects container "+grap}>
                    {projet  ? projet.map((projet) => {
                        return (
                            <TitleThumbnail title={projet.title} image={projet.featured_image.url} id={projet.slug}/>
                        )
                    })  : '' }
                    
                </div>
        </Content>
    )
}


const Content = styled.div`
.flex {
    display: flex;
}
.cols4__projects {
    row-gap: 7px;
    width: 100%;
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .project__bloc {
        width: 100%;
        @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
            width: calc(50% - 4px);
        }
        @media (min-width: 768px) { 
            width: calc(25% - 6px);
        } 
    }
}
.cols4__projects.grap4{
    gap: 4px;
    row-gap: 4px;
}
`;

export default TitleThumbnailCols4

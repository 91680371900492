import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import styled from "styled-components";
import ApiService from "../../../../../services/api.service";
import Font from "../../../../Font/Font";
import HeaderImage from "../../../../HeaderImage/HeaderImage";
import Line from "../../../../Line/Line";
import ParaL from "../../../../Texts/Para/ParaL/ParaL";
import NewRelease from "../../../News/components/MainContent/components/NewRelease";
import Image100vwCols3 from "../../../Studio/components/MainContent/components/Image100vwCols3";
import CarouselProject from "./components/CarouselProject";
import MainDescription from "./components/MainDescription";
import MoreProjects from "./components/MoreProjects/MoreProjects";
import RelatedProjects from "./components/RelatedProjects/RelatedProjects";

const MainContent = () => {
  const id = useParams();
  const [post, setPost] = useState();
  const [project, setProject] = useState();
  
  useEffect(() => {
    const getData = async() => {
      await ApiService.getPostDataById(id.id).then((res) => {
       // console.log("Post",res.data, id)
        setPost(res.data);
      });
      await ApiService.getPostData().then((res) => {
        setProject(res.data);
      });
    }

    getData();
    
  }, [id]);

  return (
    <>
      <Helmet>
          <title>{post ? post[0].title : ""}</title>
      </Helmet>
      <Content className="projects">

        <HeaderImage image={post ? post[0].image_header : ""} />
        <>
            <section>
              {post &&  post[0].liste_fix && post[0].liste_fix[0] && 
              <MainDescription title={post[0].liste_fix[0].titre} text={post[0].liste_fix[0].contenu} option={post[0].liste_fix[0].options} liste={post[0].liste_fix[0].selection} />
            }
            </section>
          <Line />
        </>
        {post && post[0].block_text != 'false'
          ? post[0].block_text[0].map((post, index) => {
              return post.acf_fc_layout === "txt_l"? (
                <>
                  <section>
                  <NewRelease title={post.titre} text={post.texte} url={post.url} option={post.options} />
                  </section>
                  <Line />
                </>
              ) : post.acf_fc_layout === "txt_s_listes" || post.acf_fc_layout === "txt_s" ? (
                <>
                  <section>
                    <MainDescription title={post.titre} text={post.contenu} option={post.options} liste={post.selection} />
                  </section>
                  <Line />
                </>
              )  : post.acf_fc_layout === "img_100" ? (
                <>
                  <Image100vwCols3 url={post.image.url} selection={post.selection} legende={post.legende}/>
                <Line />
                  
                </>
              ) : post.acf_fc_layout === "carousel" || post.acf_fc_layout === "carousel_m" ? (
                <>
                  <section>
                    <CarouselProject
                      slider={post.slide}
                      title={post.titre}
                      text={post.texte}
                      option={post.options}
                    />
                  </section>
                  <Line />{" "}
                </>
              ) : post.acf_fc_layout === "txt_m"  ? (
                <>
                  <ParaL text={post.contenu} option={post.options} />
                  <Line />
                </>
              ) : post.acf_fc_layout === "project_related" ? (
                <>
                  <section>
                    <section>
                      <RelatedProjects project={project ? project.filter( filter => post.projet.includes(filter.id)): ''} title={"RELATED PROJECTS"} />
                    </section>
                  </section>
                  <Line />{" "}
                </>
              ) : post.acf_fc_layout === "font" ? (
                <>
                  <section>
                    <Font font = {post} />
                  </section>
                  <Line />{" "}
                </>
              ) : (
                ""
              );
            })
          : ""}

        <section className="more_project">  
          <MoreProjects project={project ? project.filter( filter => filter.id != id.id).sort(() => Math.random() - Math.random()): ''} title={"MORE PROJECTS"}/>
        </section>
      </Content>
    </>
  );
};

const Content = styled.main`
.more_project {
  margin-bottom: 40px;
}

`;

export default MainContent;


import React from 'react'
import styled from 'styled-components';

const LiveInfos = ({category, live, option, showVariations}) => {
    return (    
        <Content className="live__infos container" style={{
            backgroundColor: `${ showVariations ? "#EDEDED" : option ? option.background_color : ""}`,
            color: `${option ? option.text_color : ""}`,
          }}>
            <h2 className={`live__title text text--small uppercase ${showVariations ? '' : 'text--light-grey'}`}>{category}</h2>
            <ul className={`live__text text text--small uppercase ${showVariations ? '' : 'text--light-grey'}`}>
                {live ? (<><li>{live.date}</li>
                <li>{live.heure} UTC+{live.utc}</li></>) : ''}
                
            </ul>
        </Content>
    )
}

const Content = styled.div`

`;


export default LiveInfos


import React from 'react'
import styled from 'styled-components'

const Image100vwFullScreen = ({imageUrl}) => {
    return (
        <Content className="project__image">
            <img  src={imageUrl} alt=""/>
        </Content>
    )
}


const Content = styled.figure`
overflow: hidden;
width: 100%;
height: auto;
margin: 0;
img {
    width: 100%;
    height: 100%;
}
`;

export default Image100vwFullScreen

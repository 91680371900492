import React from 'react'
import styled from 'styled-components'

const Line = () => {
    return (
        <Content className="line container"></Content>
    )
}


const Content = styled.div`
background: black;
height: 1px;
`;

export default Line

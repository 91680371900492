import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import apiService from '../../services/api.service';


const Footer = () => {
  const [data, setData] = useState([]);
  useEffect(async() => {
    await apiService.getFooterData().then((res) => {
      setData(res.data);
    })

    //loopThroughProjects(count);
  }, []);
  return (
    <Content className="footer bg bg--light-grey">
      <div className="footer__content container text text--small">
        <p className="footer__based" dangerouslySetInnerHTML={{__html: data.texte}}></p>
        <ul className="footer__social text text--highlight">
          <a className="footer__mail" href="mailto:info@nizarkazan.ch" target="_blank"><li>info@nizarkazan.ch</li></a>
          <a href="https://www.instagram.com/nizarkazan/" target="_blank"><li>@nizarkazan</li></a>
          <a href="https://www.instagram.com/nizarkazanco/" target="_blank"><li>@nizarkazanco</li></a>
          <p className="footer__copyrights">© 2019 — {new Date().getFullYear()}</p>
        </ul>
        {/* <p className="footer__imprint text text--grey uppercase"><NavLink to="/imprint">Imprint</NavLink></p>
        <p className="footer__privacy text text--grey uppercase"><NavLink to="/privacy">Privacy</NavLink></p> */}
      </div>
    </Content>
  )
}

const Content = styled.footer`
&:before {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: #E4E4E4;
  top: -1px;
  position: absolute;
}
position: absolute;
padding-top: 5px;
height: 250px;
width: 100%;

&.footer {
    .footer__content {
        grid-column: col-start / span 4;
        grid-row: 1;
        grid-row-gap: 32px;
        margin-bottom: 10px;
        @media only screen and (min-width: 576px) and (max-width: 767.98px) {
        grid-column: col-start / span 4;
        }
        @media (min-width: 768px) {
        grid-column: col-start 5 / span 4;
        }

        .footer__based {
            grid-column: col-start 1 / span 2;
            grid-row: 1;
            @media only screen and (min-width: 576px) and (max-width: 767.98px) {
              grid-column: col-start 1 / span 3;
            }
            @media (min-width: 768px) {
              grid-column: col-start 2 / span 3;
            }
        }

        .footer__social {
            grid-column: col-start 3 / span 1;
            grid-row: 1;
            @media (max-width: 576px) {
              grid-column: col-start 4 / span 3;
            }
            @media only screen and (min-width: 576px) and (max-width: 767.98px) {
              grid-column: col-start 4 / span 2;
            }
            @media (min-width: 768px) {
              grid-column: col-start 6 / span 2;
            }
          }
        }

        .footer__mail{
          grid-column: col-start 1 / span 1;
            grid-row: 2;
            color:#000000;
            li{
              margin-bottom:10px;
            }
            
            @media only screen and (min-width: 576px) and (max-width: 767.98px) {
              grid-row: 1;
              grid-column: col-start 6 / span 1;
            }
            @media (min-width: 768px) {
              grid-row: 1;
              grid-column: col-start 9 / span 1;
            }

            @media (max-width: 576px) {
              grid-column: col-start 1 / span 2;
            }
        }

        .footer__copyrights {
            grid-column: col-start 1 / span 1;
            grid-row: 2;
            color:#000000;
            margin-top:10px;
            @media only screen and (min-width: 576px) and (max-width: 767.98px) {
              grid-row: 1;
              grid-column: col-start 6 / span 1;
            }
            @media (min-width: 768px) {
              grid-row: 1;
              grid-column: col-start 9 / span 1;
            }

            @media (max-width: 576px) {
              grid-column: col-start 1 / span 2;
            }
          }
        }

        .footer__imprint {
            grid-column: col-start 3 / span 1;
            grid-row: 2;
            @media only screen and (min-width: 576px) and (max-width: 767.98px) {
              grid-row: 1;
              grid-column: col-start 7 / span 1;
            }
            @media (min-width: 768px) {
              grid-row: 1;
              grid-column: col-start 11 / span 1;
            }
          }
        }

        .footer__privacy {
            grid-column: col-start 4 / span 1;
            grid-row: 2;
            @media only screen and (min-width: 576px) and (max-width: 767.98px) {
              grid-row: 1;
              grid-column: col-start 8 / span 1;
            }
            @media (min-width: 768px) {
              grid-row: 1;
              grid-column: col-start 12 / span 1;
            }
            @media (max-width: 576px) {
              text-align: right;
              position: relative;
              right: -18px;
            }
          }
        }
    }
}
`;

export default Footer

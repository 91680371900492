import React from 'react'
import styled from 'styled-components';


const HeroImageAuto = ({image, option}) => {
    return (
        <Content className="hero" style={{backgroundColor: `${option ? option.background_color  : ''}`, color: `${option ? option.text_color : '' }`}}>
            <div className="hero__image">
                <img className="cover" src={image} alt=""/>
            </div>
        </Content>
    )
}

const Content = styled.div`
.hero__image {
    display: flex;
    justify-content: center;
    align-items: top;
    width: 100vw;
    height: 100vh;
    img {
        height: auto;
        width: 100%;
        @media (min-width: 576px) { 
            width: min-content;
            max-width: 100%;
        } 
        object-fit: contain;
        object-position: top;
    }
}
`;
export default HeroImageAuto

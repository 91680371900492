import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from "react-helmet"
import ApiService from "../../../services/api.service"
import HeaderAutoPlay from '../../HeaderAutoPlay/HeaderAutoPlay'
import Menu from '../../Menu/Menu'

const Labo = () => {
  const [slideToShow, setSlideToShow] = useState([]);
    
    useEffect(async () => {
        await ApiService.getSlider("labo").then((res) => {
            setSlideToShow(res.data);
        });
      }, []);
      
    return (
      <Fragment>
         <Helmet>
            <title>LABO</title>
        </Helmet>
        <HeaderAutoPlay images={slideToShow.slider}/>
        <Menu/>
      </Fragment>
    )
}

export default Labo

import React from 'react';
import styled from 'styled-components';
import TitleThumbnailCols4 from './components/TitleThumbnailCols4';



const RelatedProjects = ({project, title}) => {

    return (
        <Content className="projects__grid">
            <div className="cols4">
                <TitleThumbnailCols4 projet={project ? project : ''} title={title} grap="grap4"/>
            </div>
        </Content>
    )
}


const Content = styled.div`
margin-bottom: 40px;
`;

export default RelatedProjects

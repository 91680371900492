import React, { useEffect, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import Plus from '../../assets/images/plus.svg';
import ApiService from '../../services/api.service';
import CurrentDate from './components/CurrentDate';
import CurrentDateLive from './components/CurrentDateLive';


const Menu = () => {
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isMobile = windowDimension <= 576;

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = openMenu ? 'hidden' : 'auto';
  }, [openMenu])

  const [data, setData] = useState([]);

  useEffect(async () => {
    await ApiService.getShopData().then((res) => {
      setData(res.data);
      console.log("url",res.data);
    });
  }, []);
  return (   
    <>
    <Content openMenu={openMenu} className="header__content">
      {isMobile ? (
          <MobileNavbar.Wrapper className="container">
            <MobileNavbar.Flex>
                <MobileNavbar.Logo className="header__logo col-1">
                  <NavLink to="/" exact><p className="live__today text text--para col-1__text">NIZAR KAZAN <img src={Plus} alt="+"/>CO</p></NavLink>
                </MobileNavbar.Logo>
                <HamburgerButton.Wrapper onClick={() => setOpenMenu(true)}>
                    <HamburgerButton.Lines/>
                    <HamburgerButton.Lines/>
                    <HamburgerButton.Lines/>
                </HamburgerButton.Wrapper>
            </MobileNavbar.Flex>

            <MobileNavbar.MenuOpen openMenu={openMenu} className="container">
                <div className="flex">
                    <CurrentDate/>
                    <MobileNavbar.Items>
                        <MobileNavbar.Item className="text text--title"><NavLink to="/" exact>Home</NavLink></MobileNavbar.Item>
                        <MobileNavbar.Item className="text text--title"><NavLink to="/studio" exact>Studio</NavLink></MobileNavbar.Item>
                        <MobileNavbar.Item className="text text--title"><NavLink to="/projects">Projects</NavLink></MobileNavbar.Item>
                        <MobileNavbar.Item className="text text--title"><NavLink to="/news" exact>News</NavLink></MobileNavbar.Item>
                        <MobileNavbar.Item className="text text--title"><NavLink to="/labo" exact>Labo</NavLink></MobileNavbar.Item>
                        <MobileNavbar.Item className="text text--title"><a target="_blank" href={data && data.url && data.url} exact>Shop</a></MobileNavbar.Item>
                    </MobileNavbar.Items>
                    <div className="footer">
                      <MobileNavbar.Logo className="header__logo col-1">
                  <NavLink to="/" exact><p className="live__today text text--para col-1__text">NIZAR KAZAN <img src={Plus} alt="+"/>CO</p></NavLink>
                      </MobileNavbar.Logo>
                      <HamburgerCross.Wrapper onClick={() => setOpenMenu(false)}>
                          <HamburgerCross.Lines/>
                          <HamburgerCross.Lines/>
                      </HamburgerCross.Wrapper>
                    </div>
                </div>
            </MobileNavbar.MenuOpen>
          </MobileNavbar.Wrapper>
      ) : (
        <Navbar.Wrapper className="container">
          <Navbar.Flex className="flex">
            <Navbar.Logo className="header__logo col-1">
              <NavLink to="/" exact><p className="live__today text text--para col-1__text">NIZAR KAZAN <img src={Plus} alt="+"/>CO</p></NavLink>
              </Navbar.Logo>
              <CurrentDateLive className="live"/>
              <Navbar.Menu>
                  <Navbar.Items className="text text--para col-3__text col-3__sup">
                      <Navbar.Item className="menu__link"><NavLink to="/" exact activeClassName="selected">HOME,</NavLink></Navbar.Item>
                      <Navbar.Item className="menu__link"><NavLink to="/studio" exact activeClassName="selected">STUDIO,</NavLink></Navbar.Item>
                      <Navbar.Item className="menu__link"><NavLink to="/projects" exact activeClassName="selected">PROJECTS,</NavLink></Navbar.Item>
                  </Navbar.Items>
                  <Navbar.Items className="text text--para col-3__text col-3__sub">
                      <Navbar.Item className="menu__link"><NavLink to="/news" exact activeClassName="selected">NEWS,</NavLink></Navbar.Item>
                      <Navbar.Item className="menu__link"><NavLink to="/labo" exact activeClassName="selected">LABO,</NavLink></Navbar.Item>
                      <Navbar.Item className="menu__link"><a target="_blank" href={data && data.url && data.url} exact activeClassName="selected">SHOP</a></Navbar.Item>
                  </Navbar.Items>
              </Navbar.Menu>
          </Navbar.Flex>
        </Navbar.Wrapper>
      )}
    </Content>
    <Content2 openMenu={openMenu} className="header__content">
    {isMobile ? (
        <MobileNavbar.Wrapper className="container" style={{opacity:0}}>
          <MobileNavbar.Flex>
              <MobileNavbar.Logo className="header__logo col-1" style={{opacity:0}}>
                <NavLink to="/" exact><p className="live__today text text--para col-1__text">NIZAR KAZAN <img src={Plus} alt="+"/>CO</p></NavLink>
              </MobileNavbar.Logo>
              <HamburgerButton.Wrapper onClick={() => setOpenMenu(true)}>
                  <HamburgerButton.Lines/>
                  <HamburgerButton.Lines/>
                  <HamburgerButton.Lines/>
              </HamburgerButton.Wrapper>
          </MobileNavbar.Flex>

          <MobileNavbar.MenuOpen openMenu={openMenu} className="container">
              <div className="flex">
                  <CurrentDate/>
                  <MobileNavbar.Items>
                      <MobileNavbar.Item className="text text--title"><NavLink to="/" exact>Home</NavLink></MobileNavbar.Item>
                      <MobileNavbar.Item className="text text--title"><NavLink to="/studio" exact>Studio</NavLink></MobileNavbar.Item>
                      <MobileNavbar.Item className="text text--title"><NavLink to="/projects">Projects</NavLink></MobileNavbar.Item>
                      <MobileNavbar.Item className="text text--title"><NavLink to="/news" exact>News</NavLink></MobileNavbar.Item>
                      <MobileNavbar.Item className="text text--title"><NavLink to="/labo" exact>Labo</NavLink></MobileNavbar.Item>
                      <MobileNavbar.Item className="text text--title"><a target="_blank" href={data && data.url && data.url} exact>Shop</a></MobileNavbar.Item>
                  </MobileNavbar.Items>
                  <div className="footer">
                    <MobileNavbar.Logo className="header__logo col-1">
                <NavLink to="/" exact><p className="live__today text text--para col-1__text">NIZAR KAZAN <img src={Plus} alt="+"/>CO</p></NavLink>
                    </MobileNavbar.Logo>
                    <HamburgerCross.Wrapper onClick={() => setOpenMenu(false)}>
                        <HamburgerCross.Lines/>
                        <HamburgerCross.Lines/>
                    </HamburgerCross.Wrapper>
                  </div>
              </div>
          </MobileNavbar.MenuOpen>
        </MobileNavbar.Wrapper>
    ) : (
      <Navbar.Wrapper className="container">
        <Navbar.Flex className="flex">
          <Navbar.Logo className="header__logo col-1" style={{opacity:0}}>
            <NavLink to="/" exact><p className="live__today text text--para col-1__text">NIZAR KAZAN <img src={Plus} alt="+"/>CO</p></NavLink>
            </Navbar.Logo>
            <CurrentDateLive className="live" hide="yes"/>
            <Navbar.Menu style={{opacity:0}}>
                <Navbar.Items className="text text--para col-3__text col-3__sup">
                    <Navbar.Item className="menu__link"><NavLink to="/" exact activeClassName="selected">HOME,</NavLink></Navbar.Item>
                    <Navbar.Item className="menu__link"><NavLink to="/studio" exact activeClassName="selected">STUDIO,</NavLink></Navbar.Item>
                    <Navbar.Item className="menu__link"><NavLink to="/projects" exact activeClassName="selected">PROJECTS,</NavLink></Navbar.Item>
                </Navbar.Items>
                <Navbar.Items className="text text--para col-3__text col-3__sub">
                    <Navbar.Item className="menu__link"><NavLink to="/news" exact activeClassName="selected">NEWS,</NavLink></Navbar.Item>
                    <Navbar.Item className="menu__link"><NavLink to="/labo" exact activeClassName="selected">LABO,</NavLink></Navbar.Item>
                    <Navbar.Item className="menu__link"><a target="_blank" href={data && data.url && data.url} exact activeClassName="selected">SHOP</a></Navbar.Item>
                </Navbar.Items>
            </Navbar.Menu>
        </Navbar.Flex>
      </Navbar.Wrapper>
    )}
  </Content2>
  </>
  );
}


const Content = styled.div`
&.header__content {
    color: ${({ theme }) => theme.colors.darkGrey};
    z-index: 10;
    display: flex;
    align-items: center;
    height: 50px;
    position: fixed;
    bottom: 0;
    z-index : 999;
    width: 100%;
    display: flex;
    mix-blend-mode: ${({ openMenu }) =>
      openMenu ? `initial` : `difference`
    };

    left: 50%;
    transform: translate(-50%);
    @media (min-width: 576px) {
      width: calc(100% - 200px);
      bottom: 10px;
    }
    @media screen and (max-width: 480px){
      bottom: 0;
    }
    @media (min-width: 992px) {
      width: calc(100% - 440px);
      width: 100%;
      display: block;
      padding: 0 8.33%;
     }

}

`;

const Content2 = styled.div`
&.header__content {
    color: ${({ theme }) => theme.colors.darkGrey};
    z-index: 10;
    display: flex;
    align-items: center;
    height: 50px;
    position: fixed;
    bottom: 0;
    z-index : 999;
    width: 100%;
    display: flex;
    

    left: 50%;
    transform: translate(-50%);
    @media (min-width: 576px) {
      width: calc(100% - 200px);
      bottom: 10px;
    }
    @media screen and (max-width: 480px){
      bottom: 0;
    }
    @media (min-width: 992px) {
      width: calc(100% - 440px);
      width: 100%;
      display: block;
      padding: 0 8.33%;
     }

}

`;



/* ========================== Desktop ========================== */
const Navbar = {
  Wrapper: styled.nav`
  display: flex;
  align-items: flex-end;
  overflow-x: hidden;
  width: 100%;

  .selected {
    opacity: 20%;
  }
  `,

  Flex: styled.div`
  display: flex;
  @media (min-width: 576px) {
    gap: 10px;
  }
  width: 100%;
  justify-content: space-between;

  `,

  Logo: styled.h1`
  text-align: left !important;
  p {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    img {
      height: 0.75em;
      margin-top: 2px;
      @media screen and (max-width: 480px){
        margin-top: 2px;
      }
    }

  }
  `,

  Menu: styled.div`
  display: flex;
  flex-direction: column;
  `,

  Items: styled.ul`
  display: flex;
  justify-content: center;
  `,

  Item: styled.li`
  display: inline;
  &:hover {
    opacity: 60%;
  }
  + li {
      padding-left: 5px;
  }`
};


/* ========================== Mobile ========================== */
const MobileNavbar = {
  Wrapper: styled(Navbar.Wrapper)`
  `,

  MenuOpen: styled.div`
  position: fixed;
  overflow: hidden;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  background-color: white;
  li {
    &.text--title {
      margin: 0 auto;
      text-transform: uppercase;
      font-size: 3rem;
      @media screen and (max-width: 480px){
        font-size: 3.5rem;
        line-height: 3.3rem;
        letter-spacing: -0.05rem;
      }
    }
  }
  transform: ${({ openMenu }) =>
    openMenu ? `translateX(0)` : `translateX(-100%)`};
    .flex {
        /*height: 100%;*/
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gaottom: 0;

        .header__live {
          padding-top: 12px;
         }
    }

  .footer {
    color: black;
    display: flex;
    align-items: center;
    height: 50px;
    position:relative;
  }
  .text--para img{
    mix-blend-mode: difference;
  }
  `,

  Flex: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    `,

  Logo: styled(Navbar.Logo)`
  margin: 0 auto;
  `,
  Items: styled.ul`
  display: flex;
  flex-direction: column;
  color: black;
  text-align: center;
  `,
  Item: styled.li`
  `,
};

const HamburgerCross = {
    Wrapper: styled.button`
    width: 35px;
    height: 20px;
    position: absolute;
    cursor: pointer;
    top: 13px;
    right: 0;
    `,
    

    Lines: styled.span`
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: black;

    &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &:nth-child(2) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    }

    `,
}
const HamburgerButton = {
    Wrapper: styled.button`
    width: 35px;
    height: 20px;
    position: relative;
  }`,

  Lines: styled.span`
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: ${({ theme }) => theme.colors.darkGrey};

  &:nth-child(1) { top: 2px; }
  &:nth-child(2) { top: 50%; }
  &:nth-child(3) { bottom: 0; }
  `,
  };

export default withRouter(Menu)

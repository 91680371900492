import React from 'react';
import styled from 'styled-components';
import Menu from '../Menu/Menu';
import HeroImage from './components/HeroImage/HeroImage';


const Content = styled.header`
overflow: hidden;
height: 100vh;
position: relative;
@media screen and (max-width: 480px){
    height: auto;
  }
`;

const HeaderImage = ({image}) => {
    return (
        <Content className="header">
            <HeroImage image={image}/>
            <Menu/>
        </Content>
    )
}

export default HeaderImage

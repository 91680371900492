import React from 'react'
import styled from 'styled-components';



const ProjectVariations = ({slide}) => {

    

    return (
        <Container className="container">
            <Content className="content__text variations__content" >
                {slide.map((image, index) => (
                    <li key={index} className="variation">
                        <img src={image.url} alt=""/>
                    </li>
                ))}
            </Content>
        </Container>
    )
}

const Container = styled.div`
height: 600px;
overflow: auto;
scrollbar-color: transparent transparent;
scrollbar-width: 10px 10px;
grid-auto-rows: min-content;
`;

const Content = styled.ul`
display: flex;
flex-direction: column;
flex-wrap: wrap;
grid-gap: 8px;
@media (min-width: 576px) { 
    justify-content: center;
    flex-direction: row;
}

li {
    height: 220px;
}
img {
    height: 100%;
    width: auto;
    max-width: 100%;
    margin: 0 auto; 
}
`;

export default ProjectVariations

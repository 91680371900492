import React from 'react'
import styled from 'styled-components';
import ParaXL from '../Texts/Para/ParaXL/ParaXL'


const HeaderText = ({header}) => {
    return (
        <Content className="header" style={{backgroundColor: `${header ? header.background_color : ''}`, color: `${header ? header.text_color : ''}`}}>
            <Hero className="hero">
                <div className="hero__text">
                    <ParaXL text={header.texte}/>
                </div>
            </Hero>
        </Content>
    )
}

const Content = styled.header`
background: ${({ theme }) => theme.colors.darkGrey};
overflow: hidden;
height: 100vh;
position: relative;
`;

const Hero = styled.div``;

export default HeaderText

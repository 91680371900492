import React from 'react';
import styled from 'styled-components';

const ParaM = ({text}) => {
    return (
        <Content className="para--m container">
                <p className="text text--para" dangerouslySetInnerHTML={{__html:text}}></p>
        </Content>
    )
}


const Content = styled.main`
margin: 0 0 20px 0;
width: 100%;

p {
    
    grid-column: col-start 1 / span 4;

    @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
        grid-column: col-start 2 / span 6;
    }
    @media (min-width: 768px) { 
        grid-column: col-start 2 / span 10;
    } 
}
`;


export default ParaM

import React from 'react'
import styled from 'styled-components'
import Image100vwFullScreen from '../../../../../Images/Image100vwFullScreen'
import Cols3Left from '../../../../../Texts/Cols/Left/Cols3Left'
import ParaM from '../../../../../Texts/Para/ParaM/ParaM'

const Image100vwCols3 = ({url, selection, legende}) => {

    return (
        <Content className="section">
                <Image100vwFullScreen imageUrl= {url}/>
                {legende && <ParaM text={legende} />}
                <Cols3Left selection={selection}/>
        </Content>
    )
}


const Content = styled.div`
&.section {
    padding-bottom: 0px;
    img {
        margin-bottom: 0px;
    }
}
`;

export default Image100vwCols3


import React from 'react'
import styled from 'styled-components'
import TitleM from '../../../../../Texts/Titles/TitleM/TitleM'
import ParaM from '../../../../../Texts/Para/ParaM/ParaM'
import LiveInfos from './LiveInfos'
import Cols2Center from '../../../../../Texts/Cols/Center/Cols2Center'



const Moma = ({title, text, categorie, liste, live, option}) => {

    return (    
        <Content className="cols-2" style={{
            backgroundColor: `${option ? option.background_color : ""}`,
            color: `${option ? option.text_color : ""}`,
          }}>
            <div className="main__col-1">
                <TitleM title={title}/>
                <ParaM text={text}/>
            </div>
            <Cols2Center selection={liste}/>
            
            <LiveInfos category={categorie} live={live} option={option}/>
        </Content>
    )
}

const Content = styled.div`
margin-bottom: 20px;
`;


export default Moma


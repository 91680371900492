import React from 'react'
import styled from 'styled-components'
import ParaM from '../../../../../Texts/Para/ParaM/ParaM'
import TitleM from '../../../../../Texts/Titles/TitleM/TitleM'
import Cols3Left from '../../../../../Texts/Cols/Left/Cols3Left'


const TextMCols2 = ({titre, contenu, liste, option}) => {
   
    return (
        <Content className="section" style={{backgroundColor: `${option ? option.background_color  : ''}`, color: `${option ? option.text_color : '' }`}}>
            <div className="main__col-1">
                <TitleM title={titre}/>
                <ParaM text={contenu}/>
            </div>
            <Cols3Left selection={liste}/>
        </Content>
    )
}


const Content = styled.div`
&.section {
    padding-bottom: 40px;
}
`;

export default TextMCols2

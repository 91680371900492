import React, { Fragment } from 'react';
import Footer from '../../Footer/Footer';
import HeaderSlider from '../../HeaderSlider/HeaderSlider';
import Menu from '../../Menu/Menu';
import MainContent from './components/MainContent/MainContent';
;


const Homepage = () => {
    return (
      <Fragment>
        
        <HeaderSlider/>
        {/* <HeaderVideo/> */}
        <Menu/>
        <MainContent/>
        <Footer/>
      </Fragment>
    )
}

export default Homepage

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import ParaS from "../Texts/Para/ParaS/ParaS";
import TitleS from "../Texts/Titles/TitleS/TitleS";


const CarouselS = ({ title, text, slide, option }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [showVariations, setShowVariations] = useState(false);
  const totalIndex = slide !== undefined ? slide.length : 0;
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [currentImg, setCurrentImg] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML = `
           jQuery(function () {
            
            $(".ril__image").click(function(){
              console.log("hello");
              $(".ril-close").click();
            });

      });

    
        `;
    document.body.appendChild(s);
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: ".slider-nav",
    beforeChange: (current, next) => {
      let currentIndex = next + 1;
      setCurrentIndex(currentIndex);
    },
    afterChange: (current) => setShowVariations(false),
  };

  const handleClickImage = (e, image) => {
    e && e.preventDefault();
    setCurrentImg(image);
    setIsOpen(true);
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML = `
           jQuery(function () {
            
            $(".ril__image").click(function(){
              $(".ril-close").click();
            });

      });

    
        `;
    document.body.appendChild(s);
  };

  const handleCloseModal = (e) => {
    e && e.preventDefault();
    setIsOpen(false);
    setCurrentImg("");
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    focusOnSelect: true,
    arrows: false,
    infinite: false,
  };

  return (
    <Content className="carousel--s">
      <div
        className="slider"
        style={{
          backgroundColor: `${option ? option.background_color : ""}`,
          color: `${option ? option.text_color : ""}`,
        }}
      >
        <div className="main__col-1">
          <TitleS title={title} color={option ? option.text_color : ""} />
          <ParaS text={text} />
        </div>
        {isOpen && (
          <Lightbox
            style={{
              backgroundColor: `${option ? option.background_color : "fffff"}`,
              
            }}
            mainSrc={slide[currentImg].url}
            nextSrc={slide[(currentImg + 1) % slide.length]}
            prevSrc={slide[(currentImg + slide.length - 1) % slide.length]}
            onCloseRequest={handleCloseModal}
            onMovePrevRequest={() => {
              const s = document.createElement("script");
              s.type = "text/javascript";
              s.async = true;
              s.innerHTML = `
           jQuery(function () {
            
            $(".ril__image").click(function(){
              console.log("hello");
              $(".ril-close").click();
            });

      });

    
        `;
              document.body.appendChild(s);
              setCurrentImg((currentImg + slide.length - 1) % slide.length);
            }}
            onMoveNextRequest={() => {
              const s = document.createElement("script");
              s.type = "text/javascript";
              s.async = true;
              s.innerHTML = `
           jQuery(function () {
            
            $(".ril__image").click(function(){
              console.log("hello");
              $(".ril-close").click();
            });

      });

    
        `;
              document.body.appendChild(s);
              setCurrentImg((currentImg + 1) % slide.length);
            }}
          />
        )}

        <div className="carousel">
          <div className="carousel__slider">
            <div className={`slider-wrapper slider-slick ${option.fleche_color_sombre ? 'dark' : 'white'}`}>
              <Slider
                {...settingsMain}
                asNavFor={nav2}
                ref={(slider) => setSlider1(slider)}
                swipeToSlide={true}
                focusOnSelect={true}
              >
                {slide &&
                  slide[0] &&
                  slide.map((slide, index) => (
                    <div className="slick-slide slide-princ" key={slide.ID}>
                      <a onClick={(e) => handleClickImage(e, index)}>
                        <img src={`${slide.url}`} />
                      </a>
                      <label className="slick-slide-label">{slide.label}</label>
                    </div>
                  ))}
              </Slider>
              {showVariations ? (
                <div className="thumbnail-slider-wrap">
                  <div className="main__col-1">
                    <TitleS title={title} />
                    <ParaS text={text} />
                  </div>
                  <Slider
                    {...settingsThumbs}
                    asNavFor={nav1}
                    ref={(slider) => setSlider2(slider)}
                  >
                    {slide &&
                      slide[0] &&
                      slide.map((slide) => (
                        <div className="" key={slide.ID}>
                          <img
                            className="slick-slide-image"
                            src={`${slide.url}`}
                          />
                        </div>
                      ))}
                  </Slider>
                  <Footer className="container">
                    <button
                      className="btn btn--close"
                      onClick={() => setShowVariations(false)}
                    >
                      <p style={{
                        color: `${option ? option.text_color : ""}`,
                      }} className="text text--small uppercase">Close</p>
                    </button>
                    <div className="total">
                      <p className="text text--small uppercase">
                        {slide.length} img(s)
                      </p>
                    </div>
                  </Footer>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {
          <div className="slider__meta container">
            <button
              className="slider__meta--all text text--small uppercase"
              onClick={() => setShowVariations(true)}
              style={{
                color: `${option ? option.text_color : ""}`,
              }}
            >
              All
            </button>
            <p style={{
                color: `${option ? option.text_color : ""}`,
              }} className="slider__meta--pag text text--small uppercase">
              {currentIndex}/{totalIndex}
            </p>
          </div>
        }
      </div>

      
    </Content>
  );
};

const Content = styled.div`
  .slider {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 8px;
    
  }

  .slider__img {
    display: block;
    margin: 0 auto;
    overflow: hidden;
    height: 500px;
    width: calc(100% - 24px);
    @media (min-width: 576px) {
      width: auto;
      height: 600px;
    }
    img {
      height: 100%;
    }
  }

  .slider__content {
    text-align: center;
    grid-column: col-start / span 12;
  }

  .slider__meta {
    button {
      text-align: left;
      grid-column: col-start / span 1;
      grid-row: 1;  
    }
    z-index: 2;
    margin: 60px 0 0 0;
    p {
      grid-row: 1;
      margin: 0 auto;
    }
  }

  

  .carousel__slider {
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      @media (min-width: 576px) {
        object-fit: inherit;
        width: auto;
        max-width: calc(100% - 24px);
        height: 100%;
      }
    }

    .splide__slide {
      display: flex;
      justify-content: center;
    }
  }

  .slider__nav {
    svg {
      display: none;
    }
  }

  .slider__nav--arrows {
    height: 100%;
    border-radius: 0;
    width: 120px;
    @media (min-width: 576px) {
      width: 220px;
    }
    background: none;
    &:hover {
      display: block;
    }
  }

  .splide__arrow--prev {
    left: 0;
  }
  .splide__arrow--next {
    right: 0;
  }

  @media (max-width: 575.98px) {
    .splide__slide {
      width: 100vw !important;
    }
    .splide__track {
      padding: 0 !important;
    }
  }

  button {
    &.slider__nav--next {
      cursor: url(../../assets/images/arrow-next.svg), auto;
    }
    &.slider__nav--prev {
      cursor: url(../../assets/images/arrow-prev.svg), auto;
    }
  }

  .splide__pagination {
    display: none;
  }

  .variations-page {
    .variations__slider {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 700px;

      .content {
        border-bottom: 1px solid ${({ theme }) => theme.colors.darkGrey};
      }
    }
  }
`;

const Gallery = styled.div`
  text-align: center;
`;

const Footer = styled.div`
  width: 100%;
  padding: 8px 8px;
  position: absolute;
  bottom: 0;
  .btn--close {
    grid-column: col-start 1 / span 1;
    grid-row: 1;
    text-align: left;
    padding: 0;
    z-index: 2;
  }
  .total {
    grid-row: 1;
    text-align: center;
    padding: 0;
  }
`;

export default CarouselS;

import React from 'react'
import styled from 'styled-components';
import ParaM from '../../../../../Texts/Para/ParaM/ParaM';
import TitleM from '../../../../../Texts/Titles/TitleM/TitleM'
import LiveInfos from './LiveInfos'


const NewsCategories = ({title, text, categorie, live, option}) => {

    return (
        <Content style={{
            backgroundColor: `${option ? option.background_color : ""}`,
            color: `${option ? option.text_color : ""}`,
          }}>
            <div className="projects__list">
                <div className="main__col-1">
                    <TitleM title={title}/>
                    <ParaM text={text}/>
                </div>
            </div>
            <LiveInfos category={categorie} live={live} option={option}/>
        </Content>
    )
}

const Content = styled.div``;

export default NewsCategories


import React from 'react'
import styled from 'styled-components'
import Cols3Left2 from '../../../../../Texts/Cols/Left/Cols3Left2'
import ParaM from '../../../../../Texts/Para/ParaM/ParaM'
import TitleM from '../../../../../Texts/Titles/TitleM/TitleM'



const MainDescription = ({title, text, option, liste, img100}) => {

    return (    
        <Content className="section"  style={{backgroundColor: `${option ? option.background_color  : ''}`, color: `${option ? option.text_color : '' }`}}>   
            {!img100 && 
            <div className="main__col-1">
                <TitleM title={title}/>
                <ParaM text={text}/>
            </div>
            }
            <Cols3Left2 selection={liste}/>
        </Content>
    )
}

const Content = styled.div`
&.section {
    padding-bottom: 40px;
}
`;

export default MainDescription


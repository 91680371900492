import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from "react-helmet"
import apiService from '../../../services/api.service'
import Footer from '../../Footer/Footer'
import HeaderImageAuto from '../../HeaderImageAuto/HeaderImageAuto'
import HeaderText from '../../HeaderText/HeaderText'
import MainContent from './components/MainContent/MainContent'



const Studio = () => {
  const [header, setHeader] = useState([]);

  useEffect(async () => {
    await apiService.getStudioDataHeader().then((res) => {
      setHeader(res.data[0].header);
      console.log(res.data[0])
    });
  }, []);
    return (
      <Fragment>
        <Helmet>
            <title>STUDIO</title>
        </Helmet>
        {header && header.add_image   ? <HeaderImageAuto header={header}/> : <HeaderText header={header ? header : ''}/> }
        <MainContent/>
        <Footer/>
      </Fragment>
    )
}

export default Studio

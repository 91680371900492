import React from 'react'
import styled from 'styled-components'
import { useHistory } from "react-router-dom";


const MainContent = () => {
    let history = useHistory();
    
    return (
        <Content className="privacy-page">
            <div className="container">
                <button className="col-1 btn btn--close" onClick={() => history.goBack()}>
                    <p className="text text--small uppercase">Close</p>
                </button>
                <div className="col-2 content text text--small">
                    <h1 className="content__title uppercase">Imprint</h1>
                    <ul className="content__adress uppercase">
                        <li>NIZAR KAZAN + CO</li>
                        <li>Paul-Heyse-Straße 17</li>
                        <li>80336 München</li>
                        <li>Deutschland</li>
                    </ul>
                    <div className="content__text">
                        <div className="paragraph">
                            <h2>Haftung für Inhalte</h2>
                            <p>
                                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen 
                                Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte 
                                oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige 
                                Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen 
                                Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer 
                                konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte 
                                umgehend entfernen.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>Haftung für Links</h2>
                            <p>
                                Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir 
                                für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter 
                                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße 
                                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle 
                                der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen 
                                werden wir derartige Links umgehend entfernen.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>Urheberrecht</h2>
                            <p>
                                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, 
                                Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung 
                                des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. 
                                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte 
                                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. 
                                Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>Datenschutzerklärung</h2>
                            <h2>Datenschutz</h2>
                            <p>
                                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend 
                                der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. 
                                Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. 
                                Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) 
                                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>Cookies</h2>
                            <p>
                                Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, 
                                unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser 
                                speichert. Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies 
                                bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
                                Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte 
                                Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität 
                                dieser Website eingeschränkt sein.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>Server-Log-Files</h2>
                            <p>
                                Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Diese Daten sind nicht 
                                bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, 
                                wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
                            </p>
                        </div>
                        <div className="link">
                            <span>Quelle: </span><a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}


const Content = styled.main`
h1, h2 { font-size: inherit;  }
.paragraph, .link { margin-top: 10px; }
button {
    height: 0;
    text-align: left;
    padding: 0;
    position: relative;
    z-index: 200;
}
.content { margin-bottom: 20px; }
.col-1 {
    grid-column: col-start 1 / span 1;
    grid-row: 1;
    @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
        grid-column: col-start 1 / span 1;
    }
    @media (min-width: 768px) { 
        grid-column: col-start 1 / span 1;
    } 
}

.col-2 {
    grid-column: col-start 1 / span 4;
    grid-row: 1;
    @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
        grid-column: col-start 2 / span 6;
    }
    @media (min-width: 768px) { 
        grid-column: col-start 2 / span 10;
    } 

    .content__title { text-align: center; }
}
`;

export default MainContent

import http from "../http-common";

class ApiDataService{
    getSlider(page){
        return http.get(`/slider?page=${page}`);
    }

    getHomeData(){
        return http.get("/home");
    }

    getFooterData(){
        return http.get("/footer");
    }

    getShopData(){
        return http.get("/shop");
    }

    getStudioData(){
        return http.get("/studio");
    }
    getStudioDataHeader(){
        return http.get("/pages?slug=studio");
    }
    getNewsData(){
        return http.get("/news");
    }
    getNewsDataBy(categorie){
        return http.get(`/news?categorie=${categorie}`);
    }
    getPostData(){
        return http.get("/posts");
    }
    getPostDataBy(client, service){
        return http.get(`/posts?client=${client}&service=${service}`);
    }
    getPostDataById(id){
        return http.get(`/posts?slug=${id}`);
    }
    getClientData(){
        return http.get("/client");
    }
    getServiceData(){
        return http.get("/service");
    }
    getCategorieData(){
        return http.get("/news/categorie");
    }
}
export default new ApiDataService();
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";



const HeroSlider = ({ images, total }) => {

  const ref = useRef();
  const sliderRef = useRef();
  useEffect( async () => {
    let loaded = false
    let all = await total
    console.log("le total",total)
    setInterval(() => {
      if (sliderRef.current && !loaded){
        sliderRef.current.slickGoTo(Math.floor(Math.random() * (all - 1 + 1)) + 1);
        loaded=true
      }
      
    }, 5);
  }, [total]);

  const settingsMain = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 200,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    centerPadding: '120px',
    slidesToShow: 1,
    variableWidth: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          infinite: true,
          centerMode: false,
          arrows: true,
        }
      },
      {
        breakpoint: 575,
        settings: {
          infinite: true,
          arrows: true,
          centerMode: false,
        }
      }
    ]
  };



  return (
    <Content className="hero">
      <div className="hero__slider">
        <Slider
          {...settingsMain}
          ref={sliderRef}
          
        >
          {
            images
              ? images.map((image) => (
                <>
                  {image.external ? (
                    <Link
                      target="_blank"
                      to={
                        image.external
                          ? { pathname: image.external }
                          : image.project != null && image.project.slug != undefined ? { pathname: `/projects/${image.project.slug}` } : { pathname: `` }
                      }
                    >
                      <img src={image.url} alt="" />
                    </Link>
                  ) : (
                    <Link
                      to={
                        image.external
                          ? { pathname: image.external }
                          : image.project != null && image.project.slug != undefined ? { pathname: `/projects/${image.project.slug}` } : { pathname: `` }
                      }
                    >
                      <img src={image.url} alt="" />
                    </Link>
                  )}
                  <h2 className="text text--small">{image.title}</h2>
                </>
              ))
              : ""}

        </Slider>

      </div>
    </Content>
  );
};

const Content = styled.div`
  .hero__slider {
    height: 100vh;
    .slick-slider{
      height:100vh;
    }
    .cover {
      height: 100vh;
    }
    img {
      height:100vh;
    }

    h2 {
      mix-blend-mode: difference;
      color: ${({ theme }) => theme.colors.darkGrey};
      z-index: 10;
      position: absolute;
      top: 0;
      text-align: left;
      width: 100%;
      left: 0;
      padding: 5px;
    }
  }
`;

export default HeroSlider;

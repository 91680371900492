import React from 'react';
import styled from 'styled-components';

const Col1Center = ({selection}) => {
    return (
        <Content>
            <div className="col1--center container">
                {selection ? selection?.map((selection, index) => (
                  <div key={index} className={'cols-'+ index + ' container'}>
                  <h2 key={index} className={'col-'+ index + '__title text text--small'}>{(selection.type).toUpperCase()}</h2>
                  <ul key={index} className={'col-'+ index + '__text text text--small'} dangerouslySetInnerHTML={{__html: selection.post_title}}>
                  </ul>
              </div>
                )): ''}
            </div>
        </Content>
    )
}


const Content = styled.div`
.col1--center {
    padding: 0;
    .cols-0 {
        width: 100%;
        grid-row: 1;

        .col-0__title {
            grid-column: col-start 1 / span 1;
            grid-row: 1;
            @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
                grid-column: col-start 3 / span 1;
            }
            @media (min-width: 768px) { 
                grid-column: col-start 5 / span 1;
            } 
        }

        .col-0__text {
            grid-column: col-start 2 / span 3;
            grid-row: 1;
            @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
                grid-column: col-start 4 / span 3;
            }
            @media (min-width: 768px) { 
                grid-column: col-start 6 / span 3;
            } 
        }
    }
}
`;


export default Col1Center


import React from 'react'
import styled from 'styled-components'
import TitleS from '../../../../../Texts/Titles/TitleS/TitleS'
import LiveInfos from './LiveInfos'
import Col1Center from '../../../../../Texts/Cols/Center/Col1Center'

const NewReleaseCol = ({title, text, url, categorie, liste, live, option}) => {
    return (    
        <Content className="new-release__section" style={{
            backgroundColor: `${option ? option.background_color : ""}`,
            color: `${option ? option.text_color : ""}`,
          }}>  
            <Section className="container">
                <TitleS title={title}/>
                <p className="text text--title release">
                    {text} 
                    <a href={url.url} className="text text--highlight--blue"> {url.title}</a>
                </p>
            </Section>  
            <Col1Center selection={liste}/>
            <LiveInfos category={categorie} live={live} option={option}/>
        </Content>
    )
}

const Content = styled.div``;
const Section = styled.div`
text-align: center;
.release { padding-top: 8px; }
p {
    grid-column: col-start 1 / span 4;
    @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
        grid-column: col-start 2 / span 6;
    }
    @media (min-width: 768px) { 
        grid-column: col-start 2 / span 10;
    } 
}
`;



export default NewReleaseCol


import React from 'react';
import styled from 'styled-components';

const TitleXL = ({title, url}) => {
    return (
        <Content className="title--xl container">
            {url ?
            <a target="_blank" href={url}>
            <h2 className="text text--title" dangerouslySetInnerHTML={{__html: title}}></h2>
            </a>
            :
            <h2 className="text text--title" dangerouslySetInnerHTML={{__html: title}}></h2>
            }
        </Content>
    )
}


const Content = styled.main`
&.title--xl {
    text-align: center;
    @media screen and (max-width: 480px){
        padding-bottom:4px;
        h2{
            font-size:2.2rem;
            line-height:2.1rem;
        }
        
  }
}
`;


export default TitleXL

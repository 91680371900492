import React from 'react'
import styled from 'styled-components';
import Menu from '../Menu/Menu'
import HeroImageAuto from "../HeaderImageAuto/components/HeroImageAuto";

const HeaderImageAuto = ({header}) => {
    return (
        <Content className="header">
            <HeroImageAuto image={header && header.image && header.image.id && header.image.id.url} option={header} />
            <Menu/>
        </Content>
    )
}

const Content = styled.header`
overflow: hidden;
height: 100vh;
position: relative;
`;

export default HeaderImageAuto

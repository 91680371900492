import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import Live from './components/Live';



const Content = styled.main`
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
`;

const TimeOut = () => {
    const history = useHistory()

    const handleOnAction = (e) => {
        console.log('user did something', e)
        history.goBack()
    }

    const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time remaining', getRemainingTime())
    }
    
    const { getRemainingTime} = useIdleTimer({
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 300
    })

    return (
        <Content className="timeout">
            <Live/>
        </Content>
    )
}

export default TimeOut


import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';



const Live = () => {

  let min = moment().minutes();
  let result = (min < 9 ? '0' : '') + (min);

  const [d, setD] = useState(new Date());
  useEffect(() => {
    setInterval(() => setD(new Date()), 5000);
  }, []);
  let gmtHours = -d.getTimezoneOffset() / 60;
  let sign = (d.getTimezoneOffset() > 0) ? "-" : "+";


  return (
    <Content className="live col-2">
      <p className="live__meta text text--small uppercase"><div className="div-rond"></div>live</p>
      <LiveData className="live__content">
        <div className="col-2__text col-2__sup">
          <p className="live__today text text--title">
            <span className="day">{moment().format("DD")}</span>
            <span className="month">{moment().format("MM")}</span>
            <span className="year">{moment().format("YY")}</span>
          </p>
        </div>
        <div className="col-2__text col-2__sub">
          <p className="live__zone text text--title">
            <span className="hour">{moment().hours()}</span>
            <span className="minutes">{result}</span>
            <span className="utc">UTC{sign == "+" && sign}{gmtHours}</span>
          </p>
        </div>
      </LiveData>
    </Content>
  )
}

const Content = styled.div`
display: flex; 
.div-rond {
    animation-duration: .8s;
    animation-name: clignoter;
    animation-iteration-count: infinite;
    transition: none;
    background-color: red;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 4px;
    position: relative;
    top: 0;
}
@keyframes clignoter {
    0% { opacity:1; }
    40% {opacity:0; }
    100% { opacity:1; }   
   }

`;

const LiveData = styled.div`
&.live__content {
    text-align: center;
}

.live__today, 
.live__zone { 
    margin: 0;
    line-height: 100%;

    span {
        padding: 0 10px;
    }
}
`;

export default Live

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


const CurrentDateLive = ({hide}) => {

  //let d = new Date();
  const [d, setD] = useState(new Date());
  useEffect(() => {
    setInterval(() => setD(new Date()), 5000);
    console.log("hide",hide)
   
  }, []);

  
  let sign = (d.getTimezoneOffset() > 0) ? "-" : "+";
  let gmtHours = -d.getTimezoneOffset() / 60;

  let signHours = (moment().hours() < 10) ? "0" : "";
  let signMinutes = (moment().minutes() < 10) ? "0" : "";


  return (
    <Content className="header__live col-2">
      <p className="live__meta text text--ultra uppercase"><div className="div-rond"></div><span style={{opacity : hide == "yes" && 0}}>live</span></p>
      <div className="col-2__text col-2__sup" style={{opacity : hide == "yes" && 0}}>
        <p className="live__today text text--para">
          <span className="day">{moment().format("DD")}</span>
          <span className="month">{moment().format("MM")}</span>
          <span className="year">{moment().format("YY")}</span>
        </p>
      </div>
      <div className="col-2__text col-2__sub" style={{opacity : hide == "yes" && 0}}>
        <p className="live__zone text text--para">
          <span className="hour">{signHours}{moment().hours()}</span>
          <span className="minutes">{signMinutes}{moment().minutes()}</span>
          <span className="utc">UTC{sign == "+" && sign}{gmtHours}</span>
        </p>
      </div>
    </Content>
  )
}

const Content = styled.div`
.live__today,
.live__zone { 
    display: flex; 
    gap: 5px; 
}
.div-rond {
    animation-duration: .8s;
    animation-name: clignoter;
    animation-iteration-count: infinite;
    transition: none;
    background-color: red;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    display: inline-block;
    margin-right: 4px;
    position: relative;
    top: 0;
}
@keyframes clignoter {
    0% { opacity:1; }
    40% {opacity:0; }
    100% { opacity:1; }   
   }
`;

export default CurrentDateLive

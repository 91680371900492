import React from 'react'
import styled from 'styled-components';
import TitleM from '../../../../../../../Texts/Titles/TitleM/TitleM'
import Thumbnail2Cols from '../../../../../../Projects/components/MainContent/components/Thumbnail2Cols'

const TitleThumbnailCols4 = ({test, projet, title}) => {
    return (
        <Content className="projects__grid">
            <TitleM title={title} />
            <div className="cols4__projects">
                <Thumbnail2Cols projectsToRender={projet.slice(0,3)}/>
            </div>
        </Content>
    )
}


const Content = styled.div`
.flex {
    display: flex;
}
.cols4__projects {
    ul {
        width: 100%; 
        /* margin-top: 60px; */
    }
    row-gap: 7px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .project__bloc {
        width: 100%;
        @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
            width: calc(50% - 4px);
        }
        @media (min-width: 768px) { 
            width: calc(25% - 6px);
        } 
    }
}
`;

export default TitleThumbnailCols4

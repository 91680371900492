import React from 'react';
import styled from 'styled-components';

const TitleM = ({title, option}) => {
    return (
        <Content className="title--m container">
            <h3 className="col-1__title text text--para">{title}</h3>
        </Content>
    )
}


const Content = styled.main`
&.title--m {
    margin: 0;
    padding-top: 6px;
    text-align: center;
}
`;


export default TitleM

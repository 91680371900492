import React, { Fragment } from 'react'
import Footer from '../../Footer/Footer'
import MainContent from './components/MainContent/MainContent'



const ProjectDetails = () => {
    return (
      <Fragment>
         
        <MainContent/>
        <Footer/>
      </Fragment>
    )
}

export default ProjectDetails
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import IdleTimer from './components/IdleTimer/IdleTimer';
import Homepage from './components/Pages/Homepage/Homepage';
import Imprint from './components/Pages/Imprint/Imprint';
import Labo from './components/Pages/Labo/Labo';
import News from './components/Pages/News/News';
import Privacy from './components/Pages/Privacy/Privacy';
import ProjectDetails from './components/Pages/ProjectDetails/ProjectDetails';
import Projects from './components/Pages/Projects/Projects';
import Studio from './components/Pages/Studio/Studio';
import TimeOut from './components/Pages/TimeOut/TimeOut';
import { theme } from './theme';

function App() {


  return (
    <>
    <ThemeProvider theme = { theme }>
    
      <Switch>
        <Route exact path="/">
          <IdleTimer ComposedClass={Homepage} />
        </Route>
        <Route exact path="/studio">
          <IdleTimer ComposedClass={Studio} />
        </Route>
        <Route exact path="/projects">
          <IdleTimer ComposedClass={Projects} />
        </Route>
        <Route exact path="/projects/:id">
          <IdleTimer ComposedClass={ProjectDetails} />
        </Route>
        <Route exact path="/news">
          <IdleTimer ComposedClass={News} />
        </Route>
        <Route exact path="/labo">
          <IdleTimer ComposedClass={Labo} />
        </Route>
        <Route exact path="/privacy">
          <IdleTimer ComposedClass={Privacy} />
        </Route>
        <Route exact path="/imprint">
          <IdleTimer ComposedClass={Imprint} />
        </Route>
        <Route exact path="/timeout" component={TimeOut}/>
      </Switch>
    </ThemeProvider>
    </>
  );
}

export default App;

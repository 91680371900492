import React from 'react';
import styled from 'styled-components';
import placeholder from '../../../../../../assets/images/nizarkazanco.jpg';
import TitleS from '../../../../../Texts/Titles/TitleS/TitleS';
import useProgressiveImage from './useProgressiveImage';


const TitleThumbnail = ({title, image, id}) => {
    const loaded = useProgressiveImage(image)

    return (
        <Content className="project__bloc">
            <div className="project__title">
                <TitleS title={title}/>
            </div>
            <a href={`/projects/${id}`}>
                <div className="project__thumbnail" style={{backgroundImage: `url(${loaded || placeholder})`}}></div>
            </a>
        </Content>
    )
}

const Content = styled.div` 
.project__title { 
    text-align: center;
    main { padding-top: 0; }
    h3 { margin-bottom: 5px; } 
}

.project__thumbnail {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    &:before {
        content: "";
        display: inline-block;
        padding-bottom: 100%;
        vertical-align: top;
        height: 0px;
    } 
}

&:hover {
    .project__bloc,
    .title {
        opacity: 0.25;
    }
    .project__thumbnail{
        transition: all 0.2s ease 0s;
        filter: invert(100%);
    }
`;


export default TitleThumbnail

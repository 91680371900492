import React from 'react';
//import { useIdleTimer } from 'react-idle-timer';
//import { useHistory } from 'react-router';

const SESSION_IDEL_MINUTES = 5; //0.75;

const IdleTimer = (props) => {
    const { ComposedClass } = props
    /*const history = useHistory()

    const handleOnIdle = (event) => {
        console.log('user is idle', event)
        console.log('last active', getLastActiveTime())
        history.push("/timeout")
    }

    const { getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
        onIdle: handleOnIdle,
        debounce: 250,
    }) */

    return <ComposedClass />
}

export default IdleTimer
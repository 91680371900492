import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

const Video100vw = ({videoUrl}) => {
    
    return (
        <Content className="project__video">
            <div className='player-wrapper container__video'>
                <ReactPlayer
                className='react-player cover'
                url={videoUrl}
                width='100%'
                height='auto'
                loop={true}
                muted={true}
                playing={true}
                />
            </div>
        </Content>
    )
}


const Content = styled.div`
.cover {
    width: 100vw;
}
.container__video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.container__video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
`;

export default Video100vw

import React from 'react'
import styled from 'styled-components'

const TitleS = ({title, color}) => {
    return (
        <Content className="title--s container">
            <h3 style={{color : color ? color : "#000000" }} className="col-1__title title text text--small">{title}</h3>
        </Content>
    )
}


const Content = styled.main`
margin: 0;
padding-top: 8px;
text-transform: uppercase;
`;


export default TitleS

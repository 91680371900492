import React from 'react'
import styled from 'styled-components'
import { useHistory } from "react-router-dom";

const MainContent = () => {
    let history = useHistory();
    
    return (
        <Content className="privacy-page">
            <div className="container">
                <button className="col-1 btn btn--close" onClick={() => history.goBack()}>
                    <p className="text text--small uppercase">Close</p>
                </button>
                <div className="col-2 content text text--small">
                    <h1 className="content__title uppercase">Privacy</h1>
                    <div className="content__text">
                        <div className="paragraph">
                            <p>
                                Eine Nutzung der Internetseiten von Bureau Borsche ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich. 
                                Sofern eine betroffene Person besondere Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchte, 
                                könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten 
                                erforderlich und besteht für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung der 
                                betroffenen Person ein.
                            </p>
                        </div>
                        <div className="paragraph">
                            <p>
                                Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen Person, 
                                erfolgt stets im Einklang mit der Datenschutz-Grundverordnung und in Übereinstimmung mit den für die Bureau Borsche geltenden landesspezifischen 
                                Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der von 
                                uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren. Ferner werden betroffene Personen mittels dieser Datenschutzerklärung 
                                über die ihnen zustehenden Rechte aufgeklärt.
                            </p>
                        </div>
                        <div className="paragraph">
                            <p>
                                Das Bureau Borsche hat als für die Verarbeitung Verantwortlicher zahlreiche technische und organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen 
                                Schutz der über diese Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können Internetbasierte Datenübertragungen grundsätzlich 
                                Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es jeder betroffenen Person frei, personenbezogene 
                                Daten auch auf alternativen Wegen, beispielsweise telefonisch, an uns zu übermitteln.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>Begriffsbestimmungen</h2>
                            <p>
                                Die Datenschutzerklärung von Bureau Borsche beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass 
                                der Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere Kunden und 
                                Geschäftspartner einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern. 
                                Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:

                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>a) personenbezogene Daten</h2>
                            <p>
                                Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen. 
                                Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, 
                                zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, 
                                kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>b) betroffene Person</h2>
                            <p>
                                Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten von dem für die Verarbeitung Verantwortlichen verarbeitet werden.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>c) Verarbeitung</h2>
                            <p>
                                Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, 
                                die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form 
                                der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>d) Einschränkung der Verarbeitung</h2>
                            <p>
                                Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>e) Profiling</h2>
                            <p>
                                Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, 
                                die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit, 
                                Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>f) Pseudonymisierung</h2>
                            <p>
                                Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen 
                                betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, 
                                dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>g) Verantwortlicher oder für die Verarbeitung Verantwortlicher</h2>
                            <p>
                                Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel 
                                der Verarbeitung von personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise 
                                können die bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>h) Auftragsverarbeiter</h2>
                            <p>
                                Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>i) Empfänger</h2>
                            <p>
                                Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder nicht. 
                                Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>j) Dritter</h2>
                            <p>
                                Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung 
                                des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.
                            </p>
                        </div>
                        <div className="paragraph">
                            <h2>k) Einwilligung</h2>
                            <p>
                                Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, 
                                mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie betreffenden personenbezogenen Daten einverstanden ist.
                            </p>
                        </div>

                        <div className="paragraph">
                            <h2>Name und Anschrift des für die Verarbeitung Verantwortlichen</h2>
                            <p>
                                Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die:
                            </p>
                            <ul className="content__adress">
                                <li>Bureau Borsche</li>
                                <li>Mariahilfstraße 8</li>
                                <li>81541 München</li>
                                <li>Deutschland</li>
                                <li>0049 89 621 466 72</li>
                                <li><a href="mailto:info@bureauborsche.com">info@bureauborsche.com</a></li>
                                <li><a href="www.bureauborsche.com">www.bureauborsche.com</a></li>
                            </ul>
                        </div>
                        <div className="paragraph">
                            <h2>Name und Anschrift des Datenschutzbeauftragten</h2>
                            <p>Der Datenschutzbeauftragte des für die Verarbeitung Verantwortlichen ist:</p>
                            <p>WIRD NOCH AKTUALISIERT</p>
                        </div>

                        <div className="paragraph">
                            <p>Jede betroffene Person kann sich jederzeit bei allen Fragen und Anregungen zum Datenschutz direkt an unseren Datenschutzbeauftragten wenden.</p>
                        </div>

                        <div className="paragraph">
                            <h2>Cookies</h2>
                            <p>
                                Die Internetseiten der Bureau Borsche verwenden Cookies. Cookies sind Textdateien, welche über einen Internetbrowser auf einem Computersystem abgelegt und gespeichert werden.
                                Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. 
                                Sie besteht aus einer Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. 
                                Dies ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. 
                                Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt und identifiziert werden.
                            </p>
                        </div>

                        <div className="paragraph">
                            <p>Durch den Einsatz von Cookies kann die Bureau Borsche den Nutzern dieser Internetseite nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht möglich wären.</p>
                        </div>

                        <div className="paragraph">
                            <p>
                                Mittels eines Cookies können die Informationen und Angebote auf unserer Internetseite im Sinne des Benutzers optimiert werden. Cookies ermöglichen uns, wie bereits erwähnt, die Benutzer 
                                unserer Internetseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es, den Nutzern die Verwendung unserer Internetseite zu erleichtern. Der Benutzer einer Internetseite, die Cookies verwendet, 
                                muss beispielsweise nicht bei jedem Besuch der Internetseite erneut seine Zugangsdaten eingeben, weil dies von der Internetseite und dem auf dem Computersystem des Benutzers abgelegten Cookie übernommen wird. 
                                Ein weiteres Beispiel ist das Cookie eines Warenkorbes im Online-Shop. Der Online-Shop merkt sich die Artikel, die ein Kunde in den virtuellen Warenkorb gelegt hat, über ein Cookie.
                            </p>
                        </div>

                        <div className="paragraph">
                            <p>
                                Die betroffene Person kann die Setzung von Cookies durch unsere Internetseite jederzeit mittels einer entsprechenden Einstellung des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen. 
                                Ferner können bereits gesetzte Cookies jederzeit über einen Internetbrowser oder andere Softwareprogramme gelöscht werden. Dies ist in allen gängigen Internetbrowsern möglich. Deaktiviert die betroffene Person die Setzung von Cookies in dem genutzten Internetbrowser, 
                                sind unter Umständen nicht alle Funktionen unserer Internetseite vollumfänglich nutzbar.
                            </p>
                        </div>

                        <div className="paragraph">
                            <h2>Erfassung von allgemeinen Daten und Informationen</h2>
                            <p>
                                Die Internetseite der Bureau Borsche erfasst mit jedem Aufruf der Internetseite durch eine betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des Servers 
                                gespeichert. Erfasst werden können die (1) verwendeten Browsertypen und Versionen, (2) das vom zugreifenden System verwendete Betriebssystem, (3) die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt (sogenannte Referrer), (4) die Unterwebseiten, 
                                che über ein zugreifendes System auf unserer Internetseite angesteuert werden, (5) das Datum und die Uhrzeit eines Zugriffs auf die Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse), (7) der Internet-Service-Provider des zugreifenden Systems und (8) sonstige ähnliche 
                                Daten und Informationen, die der Gefahrenabwehr im Falle von Angriffen auf unsere informationstechnologischen Systeme dienen.
                                Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die Bureau Borsche keine Rückschlüsse auf die betroffene Person. Diese Informationen werden vielmehr benötigt, um (1) die Inhalte unserer Internetseite korrekt auszuliefern, (2) die Inhalte unserer Internetseite sowie die Werbung 
                                für diese zu optimieren, (3) die dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und der Technik unserer Internetseite zu gewährleisten sowie (4) um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen bereitzustellen. 
                                Diese anonym erhobenen Daten und Informationen werden durch die Bureau Borsche daher einerseits statistisch und ferner mit dem Ziel ausgewertet, den Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein optimales Schutzniveau für die von uns verarbeiteten 
                                personenbezogenen Daten sicherzustellen. Die anonymen Daten der Server-Logfiles werden getrennt von allen durch eine betroffene Person angegebenen personenbezogenen Daten gespeichert.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}



const Content = styled.main`
h1, h2 { font-size: inherit;  }
.paragraph, .link { margin-top: 10px; }
button {
    height: 0;
    text-align: left;
    padding: 0;
    position: relative;
    z-index: 200;
}
.content { margin-bottom: 20px; }
.col-1 {
    grid-column: col-start 1 / span 1;
    grid-row: 1;
    @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
        grid-column: col-start 1 / span 1;
    }
    @media (min-width: 768px) { 
        grid-column: col-start 1 / span 1;
    } 
}

.col-2 {
    grid-column: col-start 1 / span 4;
    grid-row: 1;
    @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
        grid-column: col-start 2 / span 6;
    }
    @media (min-width: 768px) { 
        grid-column: col-start 2 / span 10;
    } 

    .content__title { text-align: center; }
}
`;


export default MainContent

import React, { Fragment } from 'react'
import { Helmet } from "react-helmet"
import Footer from '../../Footer/Footer'
import Menu from '../../Menu/Menu'
import MainContent from './components/MainContent/MainContent'


const News = () => {
    return (
      <Fragment>
        <Helmet>
            <title>NEWS</title>
        </Helmet>
        <Menu/>
        <MainContent/>
        <Footer/>
      </Fragment>
    )
}

export default News

import React from 'react';
import styled from 'styled-components';
import TitleThumbnailCols4 from './components/TitleThumbnailCols4';



const MoreProjects = ({project, title}) => {
    console.log("more project",project)

    return (
        <Content className="projects__grid">
            <div className="cols4">
                <TitleThumbnailCols4 projet={project ? project : ''} title={title}/>
            </div>
        </Content>
    )
}


const Content = styled.div`
margin-bottom: 40px;
`;

export default MoreProjects

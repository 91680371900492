import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ApiService from "../../../../../services/api.service";
import Line from "../../../../Line/Line";
import ParaL from "../../../../Texts/Para/ParaL/ParaL";
import Moma from "./components/Moma";
import NewRelease from "./components/NewRelease";
import NewReleaseCol from "./components/NewReleaseCol";
import NewsCategories from "./components/NewsCategories";
import SliderInfos from "./components/Slider/SliderInfos";


const MainContent = () => {
  const [postsToShow, setPostsToShow] = useState([]);
  const [visible, SetVisible] = useState(8);
  const [options, setOptions] = useState([]);

  useEffect(async () => {
    await ApiService.getNewsData().then((res) => {
      setPostsToShow(res.data.block_flex);
      console.log(res.data);
    });
    await ApiService.getCategorieData().then((res) => {
      setOptions(res.data);
    });
  }, []);

  const handleShowMorePosts = () => {
    SetVisible((prevCount) => prevCount + 8);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => setIsOpen(!isOpen);

  const onReset = () => {
    setSelectedOption("All");
    ApiService.getNewsData().then((res) => {
      setPostsToShow(res.data.block_flex);
      console.log(res.data);
    });
    setIsOpen(false);
  };

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    ApiService.getNewsDataBy(value).then((res) => {
      setPostsToShow(res.data.block_flex);
      console.log(res.data);
    });
    //setPostsToShow(postsToShow.filter(name => name.categorie.post_title === value))
    setIsOpen(false);
  };

  return (
    <div className="news">
      <Filter className="filter bg bg--light-grey">
        <Labels className="filter__labels container">
          <div className="label__reset">
            <p className="text text--small uppercase">Filters</p>
            <button onClick={onReset}>
              <span class="material-icons loop">loop</span>
            </button>
          </div>
          <div className="labels container">
            <p className="label__title text text--small uppercase">
              Categories
            </p>
            <div className="label__options">
              <div className="select-custom text text--small">
                <DropDownContainer className="label">
                  <DropDownHeader onClick={toggling} className="dropdown--left">
                    {selectedOption || "All"}
                  </DropDownHeader>
                  {isOpen && (
                    <DropDownListContainer>
                      <DropDownList>
                        {options.map((option) => (
                          <li
                            onClick={onOptionClicked(option.post_title)}
                            key={Math.random()}
                          >
                            {option.post_title}
                          </li>
                        ))}
                      </DropDownList>
                    </DropDownListContainer>
                  )}
                </DropDownContainer>
              </div>
            </div>
          </div>
        </Labels>
      </Filter>
      {postsToShow
        ? postsToShow.slice(0, visible).map((post, index) => {
          return post.acf_fc_layout === "txt_s" ? (
            <>
              <NewsCategories title={post.titre} text={post.texte} categorie={post.categorie.post_title} live={post.live} option={post.options} />
              <Line />
            </>
          ) : post.acf_fc_layout === "txt_s_listes" ? (
            <>
              <Moma title={post.titre} text={post.contenu} categorie={post.categorie.post_title} liste={post.selection} live={post.live} option={post.options} />
              <Line />
            </>
          ) : post.acf_fc_layout === "txt_l" ? (
            <>
              <NewRelease title={post.titre} text={post.texte} url={post.url} categorie={post.categorie.post_title} live={post.live} option={post.options} />
              <Line />
            </>
          ) : post.acf_fc_layout === "txt_l_listes" ? (
            <>
              <NewReleaseCol title={post.titre} text={post.contenu} url={post.url} categorie={post.categorie.post_title} liste={post.selection} live={post.live} option={post.options} />
              <Line />
            </>
          ) : post.acf_fc_layout === "txt_m" ? (
            <>
              <ParaL text={post.contenu} option={post.options} />
              <Line />
            </>
          ) : post.acf_fc_layout === "carousel" || post.acf_fc_layout === "carousel_m" ? (
            <>
              <SliderInfos title={post.titre} slide={post.slide} text={post.texte} categorie={post.categorie.post_title} live={post.live} option={post.options} />
              <Line />
            </>
          ) : (
            ""
          );
        })
        : ""}

      <SeeMore className={
        visible >= postsToShow.length
          ? `see-more hidden`
          : `see-more`
      }>
        <button className="text text--title text--light-grey uppercase"
          onClick={handleShowMorePosts}
        >
          Load More
        </button>
      </SeeMore>
    </div>
  );
};

const SeeMore = styled.div`
  .hidden {
    display: none;
  }
`;

const Filter = styled.div`
  &.filter {
    padding: 5px 0;
    height: 250px;
    @media (min-width: 576px) { 
        height: 125px;
     }

    .filter__labels {
      .label__reset {
        grid-column: col-start / span 1;
        grid-row: 1;
        @media screen and (max-width: 480px){
          margin-top: 10px;
        }

        .loop {
          transform: rotate(-270deg);
          font-size: 0.6875rem;
          border: 0.8px solid white;
          border-radius: 50px;
          padding: 4px;
          margin-top: 5px;

          &:hover {
            border: 0.8px solid black;
          }
        }
      }
      .labels {
        width: 100%;
        padding: 0;
        grid-row: 5; 
        @media (min-width: 576px) { 
            grid-row: 1; 
         }
         @media screen and (max-width: 480px){
          grid-row: 4; 
        }
        .label__title {
          grid-column: col-start 1 / span 1;
          grid-row: 1; 
          @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
              grid-column: col-start 7 / span 2;
          }
          @media (min-width: 768px) { 
              grid-column: col-start 6 / span 1;
           }

           @media (max-width: 576px){
              display: flex;
              align-items: center;
           }
        }
        .label__options {
          position:relative;
          grid-row: 2;
          grid-column: col-start 1 / span 3;
          @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
              grid-column: col-start 7 / span 2;
          }
          @media (min-width: 768px) { 
              grid-column: col-start 6 / span 2;
           }
        }
      }
    }
  }

  button {
    padding: 0;
  }
`;

const Labels = styled.div`
grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
grid-row-gap: 0.8em;
@media screen and (max-width: 480px){
  grid-row-gap: 0.5em;
}

@media (min-width: 576px) { 
    grid-template-rows: inherit;
 }`;

const DropDownContainer = styled("div")`
  width: 100%;
  margin: 0;
  
`;

const DropDownHeader = styled("div")`
  color: black;
  border-bottom: 0.5px solid white;
`;

const DropDownListContainer = styled("div")`
position: absolute;
z-index: 100;
width: 104%;
max-height: 160px;
overflow-y: auto;
padding: 0px;
padding-left 4%;
right:0;
background: #E4E4E4;
padding-bottom: 20px;
border-radius: 0 0 4px 4px;
box-shadow: 0px 2px 2px 0px #ada6a6;
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
  
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  color: black;
  li:hover{
    opacity:0.8;
  }
  &:first-child {
    padding-top: 0.8em;
  }
  li{
    line-height: 1rem;
    cursor: pointer;  
  }
`;

export default MainContent;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TitleM from "../Texts/Titles/TitleM/TitleM";



const Font = ({ font }) => {
    const fontName = "";

    const [styleFont, setStyleFont] = useState(null);
    const [styleCustom, setStyleCustom] = useState(null);
    
    useEffect(()=>{
      console.log("fontsssss",font)
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `
           jQuery(function () {
            
            $(".menu-my-account").idTabs({});
            
        jQuery("#slider-range-max").slider({
          range: "max",
          min: 0,
          max: 1000,
          value: 150,
          slide: function (event, ui) {
            jQuery("#amount").val(ui.value);
          }
        });
        jQuery("#amount").val(jQuery("#slider-range-max").slider("value"));

        jQuery("#font-size-range").slider({
          range: "max",
          min: ${font.min_value},
          max: ${font.max_value},
          value: ${font.default_value},
          slide: function (event, ui) {
            var valFS = ui.value;
            var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
            contentStyle.css({
              "font-size": +valFS + "px",
              "line-height": +valFS + "px"
            });
          }
        });

        jQuery("#letter-spacing-range, #letter-spacing-range2").slider({
          range: "max",
          min: ${font.letter_spacing_min},
          max: ${font.letter_spacing_max},
          value: ${font.letter_spacing_default},
          slide: function (event, ui) {
            var valFS = ui.value / 100;
            var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
            contentStyle.css({
              "letter-spacing": +valFS + "px"
            });
          }
        });

        jQuery("#line-height-range, #line-height-range2").slider({
          range: "max",
          min: ${font.min_value},
          max: ${font.max_value},
          value: ${font.default_value},
          slide: function (event, ui) {
            var valFS = ui.value;
            var contentStyle = jQuery(this).parents('.elts-style').children('.content-style').children('p');
            contentStyle.css({
              "line-height": +valFS + "px"
            });
          }
        });

        jQuery(".elts-style").each(function () {

          var alignText = jQuery(this).children('.control-font').children('.col-md-12').children('.align-text-transform').children('.align').children('a');
          var texTransform = jQuery(this).children('.control-font').children('.col-md-12').children('.align-text-transform').children('.text-transform').children('a');
          var fontFeature = jQuery(this).children('.control-font').children('.col-md-12').children('.font-feature').children('a');
          var childT = jQuery(this).children('.content-style');

          alignText.on('click', function () {
            var ClassAlign = jQuery(this).attr('class');
            childT.css({
              "text-align": ClassAlign
            })
            jQuery('.align a').removeClass('active');
            jQuery(this).addClass('active');
          });

          texTransform.on('click', function () {
            var ClassTransfrom = jQuery(this).attr('class');
            childT.css({
              "text-transform": ClassTransfrom
            })
            jQuery('.text-transform a').removeClass('active');
            jQuery(this).addClass('active');
          });

          fontFeature.on('click', function () {
            var ClassfontFeature = jQuery(this).attr('class');
            childT.toggleClass(ClassfontFeature)
            jQuery(this).toggleClass('active');
          });

        });

        jQuery('.removeStyle').on('click', function () {
          jQuery('.content-style p').removeAttr("style");
          jQuery('.content-style').removeAttr("style");
          jQuery('.content-style').removeClass("SS01");
          jQuery('.content-style').removeClass("SS02");
          jQuery('.content-style').removeClass("SS03");
          jQuery('.content-style').removeClass("SS04");
          jQuery('.font-feature a').removeClass("active");
          return false;
        });

        jQuery(".list-typeface").each(function () {
          var inputC = jQuery(this).children('input');
          var idC = inputC.attr("id");
          if (jQuery(this).children('input').is(":checked")) {
            jQuery("label." + idC).addClass('checked');
          } else {
            jQuery("label." + idC).removeClass('checked');
          }
          inputC.change(function () {
            if (jQuery(this).is(":checked")) {
              jQuery("label." + idC).addClass('checked');
            } else {
              jQuery("label." + idC).removeClass('checked');
            }
          });
        });
           

        function moveUp(item) {
          var prev = item.prev();
          if (prev.length == 0)
            return;
          prev.css('z-index', 999).css('position', 'relative').animate({top: item.height()}, 250);
          item.css('z-index', 1000).css('position', 'relative').animate({top: '-' + prev.height()}, 300, function () {
            prev.css('z-index', '').css('top', '').css('position', '');
            item.css('z-index', '').css('top', '').css('position', '');
            item.insertBefore(prev);
          });
        }
        setTimeout(function(){ 
            jQuery( ".list-typeface").each(function() {
                var inputC = jQuery(this).children('input');
                var idC = inputC.attr("id");
                inputC.change(function(){
                    if(jQuery(this).is(":checked")) {
                        jQuery("label."+idC).addClass('checked');
                    } else {
                        jQuery("label."+idC).removeClass('checked');
                    }
                });
              });
        }, 2000);
        function moveDown(item) {
          var next = item.next();
          if (next.length == 0)
            return;
          next.css('z-index', 999).css('position', 'relative').animate({top: '-' + item.height()}, 250);
          item.css('z-index', 1000).css('position', 'relative').animate({top: next.height()}, 300, function () {
            next.css('z-index', '').css('top', '').css('position', '');
            item.css('z-index', '').css('top', '').css('position', '');
            item.insertAfter(next);
          });
        }

        //$(".FieldContainer").sortable({ items: ".elts-style", distance: 10 });
        $('.order button').click(function () {
          var btn = $(this);
          var val = btn.val();
          if (val == 'up')
            moveUp(btn.parents('.elts-style'));
          else
            moveDown(btn.parents('.elts-style'));
        });
        
      
        // $(".btn-copy").on('click', function(){
        //   $('.elts-style').clone().appendTo(".font-style");
        // })

      });

    
        `;
        document.body.appendChild(s);
        
                var newStyle = document.createElement('style');
                newStyle.appendChild(document.createTextNode("\
                    @font-face {\
                        font-family: '" + font.titre + "';\
                        src: url('" + font.font.url + "');\
                    }\
                "));
                
                document.head.appendChild(newStyle); 

                var styleClass = document.createElement('style');
                styleClass.appendChild(document.createTextNode("\
                    .customClass {\
                        font-family: '" + font.titre  + "' !important;\
                    }\
                "));
                document.head.appendChild(styleClass);
    },[])

     
    
    const onChangeStyle = (e) => {
      if(styleFont)
      document.head.removeChild(styleFont); 
      
      var element = font.fonts;
      var newStyle, styleClass = null;
      //console.clear()
      //console.log(font.fonts)
      
      var result = element.filter(name => name.titre === e.target.value);
       //console.log(result)

       newStyle = document.createElement('style');
                newStyle.appendChild(document.createTextNode("\
                    @font-face {\
                        font-family: '" + font.titre + "';\
                        src: url('" + result[0].font + "');\
                    }\
                "));
                setStyleFont(newStyle)
                
                document.head.appendChild(newStyle); 

                styleClass = document.createElement('style');
                styleClass.appendChild(document.createTextNode("\
                    .customClass {\
                        font-family: '" + font.titre  + "' !important;\
                    }\
                "));
                setStyleCustom(styleClass)
                //console.log(styleClass)
                document.head.appendChild(styleClass);
          
    }
  return (
    <Content className="fonts" > 
        <>
          {/* Story */}
          <div className="col-md-12 short-story" id="story">
            <div className="col-md-12" style={{
        backgroundColor: font.background_color
      }}>
              <div className="col-md-10 container-center list-account">
                <TitleM title={font.titre} />
              </div>
              <div className="col-md-12">
                <div className="font-style">
                  {/* Content editable */}
                  <div className="elts-style">
                    <ControlFont className="control-font col-md-12">
                      <SelectFont className="select-font">
                        <select className="fontselect"  onChange={ e => onChangeStyle(e)}>
                          
                          {font.fonts ? font.fonts.map(option => (<option value={option.titre} >
                            {font.titre} {option.titre}
                          </option>)) : ''}
                        </select>
                      </SelectFont>
                      {/* Font size Font */}
                      <div className="font-size">
                        <div className="col-md-12">
                          <div
                            id="font-size-range"
                            className="slider-range-max"
                          />
                        </div>
                      </div>
                      {/* Font size Font */}
                      {/* Line height Font */}
                      <div className="letter-spacing">
                        <div className="col-md-12">
                          <div
                            id="letter-spacing-range"
                            className="slider-range-max"
                          />
                        </div>
                      </div>
                      {/* Line height Font */}
                      {/* Line height Font */}
                      <div className="line-height">
                        <div className="col-md-12">
                          <div
                            id="line-height-range"
                            className="slider-range-max"
                          />
                        </div>
                      </div>
                      {/* Line height Font */}
                      {/* Style Font */}
                      <div className="col-md-12 overflow-h">
                        <div className="align-text-transform">
                          <div className="align">
                            <a href="#0" className="left active">
                              {/*?xml version="1.0" encoding="UTF-8"?*/}
                              <svg
                                width="21px"
                                height="20px"
                                viewBox="0 0 21 20"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                {/* Generator: Sketch 53.2 (72643) - https://sketchapp.com */}
                                <title>Group</title>
                                <desc>Created with Sketch.</desc>
                                <g
                                  id="——-TYPEFACE"
                                  stroke="none"
                                  strokeWidth={1}
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <g
                                    id="PRODUIT-OK-Copy-21"
                                    transform="translate(-130.000000, -3142.000000)"
                                    fill="#FFFFFF"
                                  >
                                    <g
                                      id="Group-13"
                                      transform="translate(130.000000, 3090.000000)"
                                    >
                                      <g
                                        id="Group"
                                        transform="translate(0.000000, 52.000000)"
                                      >
                                        <path
                                          d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M0,5.80300493 L0,4.75369458 L13.6402956,4.75369458 L13.6402956,5.80300493 L0,5.80300493 Z M0,15.2463054 L0,14.1969951 L13.6402956,14.1969951 L13.6402956,15.2463054 L0,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z"
                                          id="Fill-5"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </a>
                            <a href="#0" className="center">
                              {/*?xml version="1.0" encoding="UTF-8"?*/}
                              <svg
                                width="21px"
                                height="20px"
                                viewBox="0 0 21 20"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                {/* Generator: Sketch 53.2 (72643) - https://sketchapp.com */}
                                <title>Fill 5</title>
                                <desc>Created with Sketch.</desc>
                                <g
                                  id="——-TYPEFACE"
                                  stroke="none"
                                  strokeWidth={1}
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <g
                                    id="PRODUIT-OK-Copy-21"
                                    transform="translate(-181.000000, -3142.000000)"
                                    fill="#FFFFFF"
                                  >
                                    <g
                                      id="Group-13"
                                      transform="translate(130.000000, 3090.000000)"
                                    >
                                      <g
                                        id="Group"
                                        transform="translate(51.000000, 52.000000)"
                                      >
                                        <path
                                          d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M4.19699507,5.80300493 L4.19699507,4.75369458 L16.7880296,4.75369458 L16.7880296,5.80300493 L4.19699507,5.80300493 Z M4.19699507,15.2463054 L4.19699507,14.1969951 L16.7880296,14.1969951 L16.7880296,15.2463054 L4.19699507,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z"
                                          id="Fill-5"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </a>
                            <a href="#0" className="right">
                              {/*?xml version="1.0" encoding="UTF-8"?*/}
                              <svg
                                width="21px"
                                height="20px"
                                viewBox="0 0 21 20"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                {/* Generator: Sketch 53.2 (72643) - https://sketchapp.com */}
                                <title>Group</title>
                                <desc>Created with Sketch.</desc>
                                <g
                                  id="——-TYPEFACE"
                                  stroke="none"
                                  strokeWidth={1}
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <g
                                    id="PRODUIT-OK-Copy-21"
                                    transform="translate(-232.000000, -3142.000000)"
                                    fill="#FFFFFF"
                                  >
                                    <g
                                      id="Group-13"
                                      transform="translate(130.000000, 3090.000000)"
                                    >
                                      <g
                                        id="Group"
                                        transform="translate(102.000000, 52.000000)"
                                      >
                                        <path
                                          d="M0,10.5247783 L0,9.47527094 L20.9852217,9.47527094 L20.9852217,10.5247783 L0,10.5247783 Z M7.34472906,5.80300493 L7.34472906,4.75369458 L20.9852217,4.75369458 L20.9852217,5.80300493 L7.34472906,5.80300493 Z M7.34472906,15.2463054 L7.34472906,14.1969951 L20.9852217,14.1969951 L20.9852217,15.2463054 L7.34472906,15.2463054 Z M0,1.08142857 L0,0.0319211823 L20.9852217,0.0319211823 L20.9852217,1.08142857 L0,1.08142857 Z M0,19.9680788 L0,18.9185714 L20.9852217,18.9185714 L20.9852217,19.9680788 L0,19.9680788 Z"
                                          id="Fill-7"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </a>
                          </div>
                          <div className="text-transform">
                            <a href="#0" className="initial active">
                              Aa
                            </a>
                            <a href="#0" className="uppercase">
                              AA
                            </a>
                            <a href="#0" className="lowercase">
                              aa
                            </a>
                          </div>
                        </div>
                        <div className="font-feature">
                          <a style={{color : font.background_color}} href="#0" className="SS01">
                            SS01
                          </a>
                          <a style={{color : font.background_color}} href="#0" className="SS02">
                            SS02
                          </a>
                          <a style={{color : font.background_color}} href="#0" className="SS03">
                            SS03
                          </a>
                          <a style={{color : font.background_color}} href="#0" className="SS04">
                            SS04
                          </a>
                        </div>
                      </div>
                      {/* Style Font */}
                      <a href="#0" className="removeStyle" />
                    </ControlFont>
                    <div className="remove">
                      <a href="#0" />
                    </div>
                    <div className="content-style">
                      <p
                        contentEditable="true"
                        
                        className={`customClass ${''/* divStyle */}`}
                        style={{
                          fontFamily: fontName,
                          fontSize:font.default_value+"px",
                          lineHeight:font.default_value+"px",
                          letterSpacing: (font.letter_spacing_default/100)+"px",

                        //   fontWeight: this.state.fontWeight,
                        //   fontStyle: this.state.fontStyle,
                        }}
                        suppressContentEditableWarning={true}
                      >
                        {font.content}
                      </p>
                    </div>
                    <div className="order">
                      <button value="up" className="up">
                        Up
                      </button>
                      <button value="down" className="down">
                        Down
                      </button>
                    </div>
                  </div>
                  {/* Content editable */}
                  {/* Content editable */}
                  
                </div>
                
              </div>
            </div>
          </div>
          {/* Story */}
        </>
    </Content>
  );
};



const ControlFont = styled.div`
padding: 0 8.33% 20px 8.33%;
overflow: hidden;
position: relative;

.col-md-12,
&.col-md-12 {
  padding-top: 5px; 
}

.font-size,
.line-height,
.letter-spacing {
  width: 25%;
  float: left;
  padding: 0 0 10px 85px;
  background: url(../images/icon-font-size.svg) no-repeat 45px center; 
  @media (max-width: 768px) { 
    width:100% ;
    padding: 0 0 10px 30px;
    margin: 10px 0;
  background: url(../images/icon-font-size.svg) no-repeat 0px center;
 }
  input { display: none; }
}

.line-height { background: url(../images/icon-line-height.svg) no-repeat 65px center; }
.letter-spacing {background: url(../images/icon-letter-spacing.svg) no-repeat 45px center; }
@media (max-width: 768px) { 
.line-height { background: url(../images/icon-line-height.svg) no-repeat 0px center; }
.letter-spacing {background: url(../images/icon-letter-spacing.svg) no-repeat 0px center; }
}

.align-text-transform { 
  width: 25%;
  float: left;
  @media (max-width: 768px) { 
    width:100% ;
 }

  .align {
    float: left;
    padding-top: 10px;

    a {
      float: left;
      margin-right: 20px;

      path {
        fill: #000000;
      }
      &:hover path {
        fill: #ffffff;
      }
    }
    
  }

  .text-transform {
    float: right;
    text-align: right;

    a {
      display: inline-block;
      width: 36px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      font-size: 13px;
      color: rgb(239, 239, 239);
      background: #000000;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
    }

    a:hover,
    a:active {
      background: #ffffff;
    }
  }
}

.font-feature {
  float: right;

  a {
    display: inline-block;
    width: 60px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 13px;
    color: #ffffff;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    background: #000000;

    &:hover,
    &:active {
      color: #000000;
      background: #ffffff;
    }
  }
}

.removeStyle {
  position: absolute;
  width: 22px;
  height: 22px;
  background: url(../images/icon-initial.svg) no-repeat left center;
  right: 10px;
  top: 10px;
  display: block;
}
`;

const SelectFont = styled.div`
&.select-font {
  width: 25%;
  float: left;
  position: relative;
  border-top: 1px solid #3C3C3C;
  border-bottom: 1px solid #3C3C3C; 

  &:before {
    position: absolute;
    right: 10px;
    top: 34%;
    content: "↓";
    pointer-events: none;  
  }

  select {
    height: 35px;
    font-size: 13psx;
    color: #000000;
    line-height: 14px;
    letter-spacing: -0.1px;
    padding: 5px 10px 5px 0;
    display: block;
    border: 0;
    background: none;
    width: 100%;
    -webkit-appearance: none;
  }
  @media (max-width: 768px) { 
    width:100%;
 }
}

`;




const Content = styled.div`
  overflow: hidden;
  position: relative;

  .font-style {
    padding-bottom: 80px;
    overflow: hidden;

    .elts-style {
      padding-top: 10px; 

      &:hover {
        .control-font {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .remove,
    .order {
      width: 8.33%;
      float: left;
    }

    .remove {
      a {
        width: 19px;
        height: 19px;
        display: block;
        margin: 10px 0 0 10px;
        background: url(../images/categorie-all.svg) no-repeat center center;
      }
    }

    .order {
      text-align: right;
      padding-right: 12px;

      button {
        width: 16px;
        height: 8.67px;
        display: block;
        margin: 0px 0px 5px 0px;
        border: 0;
        cursor: pointer;
        text-indent: -9999px;
        float: right;
        clear: both;

        &.up { background: url(../images/btn-up.svg) no-repeat center center; }
        &.down { background: url(../images/btn-down.svg) no-repeat center center; }
      }
    }

    .content-style {
      width: 83.33%;
      float: left;
      overflow: hidden;
      overflow-y: auto;

      

      &.SS01 {
        p {
          -moz-font-feature-settings: "ss01";
          -webkit-font-feature-settings: "ss01";
          font-feature-settings: "ss01";
        }
      }

      &.SS02 {
        p {
          -moz-font-feature-settings: "ss02";
          -webkit-font-feature-settings: "ss02";
          font-feature-settings: "ss02";
        }
      }

      &.SS03 {
        p {
          -moz-font-feature-settings: "ss03";
          -webkit-font-feature-settings: "ss03";
          font-feature-settings: "ss03";
        }
      }

      &.SS04 {
        p {
          -moz-font-feature-settings: "ss04";
          -webkit-font-feature-settings: "ss04";
          font-feature-settings: "ss04";
        }
      }
    }
  }

  
  .slider-range-max {
    width: calc(100% - 23px);
    height: 23px;
    border: 0;
    position: relative; 

    &:before {
      content: "";
      width: calc(100% + 23px);
      height: 2px;
      z-index: 1;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -1px;
      background: #000000;
    }

    .ui-slider-range-max {
      background: none;
    }

    .ui-slider-handle {
      width: 23px;
      height: 23px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      background: #000000;
      margin: 0;
      position: absolute;
      z-index: 2;
      top: 0;
    }
  
  }
  
  .list-collections {
    display: block;
    padding-top: 10px; 

    ul {
      display: block;

      li {
        display: block;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: -0.2px;
      }
    }
  }
  
  .specific-font {
    width: 100%;
    margin-top: 5px;
    padding: 5px 5px 20px;
    background: #F7F7F7;
    overflow: hidden; 

    .col-md-10 {
      p { float: left; }
      span {
        float: right;
        font-size: 10px;
        color: #D9D9D9;
      }
    }

    .img {
      display: block;

      img {
        display: block;
        float: right;
      }
    }
  
  }
  .list-font-collections {
    width: 100%;
    overflow: hidden;
    padding: 45px 12px 12px 12px; 

    p {
      padding-left: 8.33%;
    }
  
    ul {
      li {
        display: block;
        margin-top: 3px;
        overflow: hidden;

        .col-md-10 {
          font-size: 72px;
          line-height: 70px;
          letter-spacing: -0.4px;
          padding: 12px;
          background: #F7F7F7;
          overflow: hidden;
          white-space: nowrap;
        }

        .for-select {
          text-align: right;
          padding-right: 10px;

          div {
            float: left;
            padding-right: 5px;
            width: calc(100% - 10px);

            .selected {
              display: none;
            }
          }

          .list-typeface {
            width: 100%;

            label {
              width: 10px;
              height: 10px;
              line-height: 10px;
              padding: 0;
              display: block;
              float: right;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              background: #ffffff;

              &.checked {
                background: #00FF00;

                + div .selected {
                  display: block;
                }

                + div .prix {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .top-list {
    .content-style {
      padding-bottom: 5px;
      border-bottom: 1px solid #000000;

      p {
        font-size: 22px;
        line-height: 24px;
        letter-spacing: -0.3px;
      }
    }

    .control-font {
      display: block;
      padding: 0;
      font-size: 0;

      .align-text-transform {
        width: auto; 

        .text-transform {
          a {
            background: #EBEBEB;
            color: #ffffff;
          }
        }
      }

      .font-feature {
        a {
          background: #EBEBEB;
          color: #ffffff;
        }
      }
    }
  }
`;


export default Font;
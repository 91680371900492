import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiService from "../../../../../../services/api.service";
import Thumbnail2Cols from './Thumbnail2Cols';

const FilterProjects = () => {
    const [isServicesOpen, setIsServicesOpen] = useState(false);
    const [isClientsOpen, setIsClientsOpen] = useState(false);
    const [selectedServicesOption, setSelectedServicesOption] = useState(null);
    const [selectedClientsOption, setSelectedClientsOption] = useState(null);
    const [optionsServices, setOptionsServices] = useState(null);
    const [optionsClients, setOptionsClients] = useState(null);

    const [projectsToShow, setProjectsToShow] = useState([]);
    const [client, setClient] = useState("All");
    const [service, setService] = useState("All");
    const [visible, SetVisible] = useState(12)


    const servicesToggling = () => setIsServicesOpen(!isServicesOpen);
    const clientsToggling = () => setIsClientsOpen(!isClientsOpen);

    const handleShowMoreProjects = () => {
        SetVisible((prevCount) => prevCount + 9);
        /* setTimeout(() => {
            scrollElement();
        }, 300); */
        
      };


      const scale = (num, in_min, in_max, out_min, out_max) => {
        return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
      };

      const scrollElement = () => {
        var $w = $(window),
        $canvases = $(".project__img--square");
    
    
    $w.scroll(function() {
        var d = $w.scrollTop() + 100;
        $canvases.each(function() {
            var $this = $(this),
                ptop = $this.offset().top,
                total = ptop + $this.height();
                if(d >= (ptop) )
               $this[0].style.filter = `blur(${scale(100, 0, 100, 30, 0)}px)`
        });
    });
      }

    const onReset = () => {
        setSelectedServicesOption('All');
        setSelectedClientsOption('All');
        setIsServicesOpen(false);
        setIsClientsOpen(false);
        ApiService.getPostData().then((res) => {
            setProjectsToShow(res.data);
          });
    }

    const onServicesOptionClicked = value => () => {
      setSelectedServicesOption(value);
      setService(value)
      if(value === 'All' && client === 'All')
        ApiService.getPostData().then((res) => {
            setProjectsToShow(res.data);
          });
        else if(value !== 'All' && client === 'All')
        ApiService.getPostDataBy('', value).then((res) => {
            setProjectsToShow(res.data);
          });
        else if (client !== undefined && value === "All")
        ApiService.getPostDataBy(client, '').then((res) => {
            setProjectsToShow(res.data);
          });
          else if (client !== undefined && value !== "All")
          ApiService.getPostDataBy(client, value).then((res) => {
              setProjectsToShow(res.data);
            });
      //else

      //console.log(projectsToShow)
      //setProjectsToShow(projectsToShow.filter(name => name.service.find(service => service.post_title === value)))
      setIsServicesOpen(false);
    };

    const onClientsOptionClicked = value => () => {
        setSelectedClientsOption(value);
        setClient(value)
       // console.log(service)
        if(value === 'All' && service === 'All')
        ApiService.getPostData().then((res) => {
            setProjectsToShow(res.data);
          });
          else if(value !== 'All' && service === 'All')
          ApiService.getPostDataBy(value, '').then((res) => {
            setProjectsToShow(res.data);
          });
          else if(service !== undefined && value === 'All')
          ApiService.getPostDataBy('', service).then((res) => {
            setProjectsToShow(res.data);
          });
          else if(service !== undefined && value !== 'All')
          ApiService.getPostDataBy(value, service).then((res) => {
            setProjectsToShow(res.data);
          });
        //setProjectsToShow(projectsToShow.filter(name => name.client.post_title === value))
        //else

        //console.log(projectsToShow)
        setIsClientsOpen(false);
      };


      useEffect(async() => {
        await ApiService.getPostData().then((res) => {
          setProjectsToShow(res.data);
        });
        /*await ApiService.getCategoryData().then((res) => {
            setOptionsCategories(res.data);
          });*/
        await ApiService.getClientData().then((res) => {
            setOptionsClients(res.data);
          });
        await ApiService.getServiceData().then((res) => {
        setOptionsServices(res.data);
        });
        //setCount((prevCount) => prevCount + 1);
        //scrollElement();

      }, []);

    return (
        <Content>
            <Filter className="filter bg bg--light-grey">
                <Labels className="filter__labels container">
                    <div className="label__reset">
                        <p className="text text--small uppercase">Filters</p>
                        <button onClick={onReset}>
                            <span class="material-icons loop">loop</span>
                        </button>
                    </div>
                    <div className="labels col-2 container">
                        <div className="col-2__title"><p className="label__title text text--small uppercase">Services</p></div>
                        <div className="col-2__options">
                            <div className="select-custom text text--small">
                                <DropDownContainer>
                                    <DropDownHeader onClick={servicesToggling} className="dropdown--left">
                                    {selectedServicesOption || "All"}
                                    </DropDownHeader>
                                    {isServicesOpen && (
                                    <DropDownListContainer>
                                        <DropDownList>
                                        <li onClick={onServicesOptionClicked('All')}>All</li>
                                        {optionsServices && optionsServices[0] && optionsServices.map(option => (
                                            <li onClick={onServicesOptionClicked(option.post_title)} key={Math.random()}>
                                            {option.post_title}
                                            </li>
                                        ))}
                                        </DropDownList>
                                    </DropDownListContainer>
                                    )}
                                </DropDownContainer>
                            </div>
                        </div>
                    </div>
                    <div className="labels col-3 container">
                        <div className="col-3__title"><p className="label__title text text--small uppercase">Clients</p></div>
                        <div className="col-3__options">
                            <div className="select-custom text text--small">
                                <DropDownContainer>
                                    <DropDownHeader onClick={clientsToggling} className="dropdown--right">
                                    {selectedClientsOption || "All"}
                                    </DropDownHeader>
                                    {isClientsOpen && (
                                    <DropDownListContainer className="container-3">
                                        <DropDownList>
                                        <li onClick={onClientsOptionClicked('All')}>All</li>
                                        {optionsClients.map(option => (
                                            <li onClick={onClientsOptionClicked(option.post_title)} key={Math.random()}>
                                            {option.post_title}
                                            </li>
                                        ))}
                                        </DropDownList>
                                    </DropDownListContainer>
                                    )}
                                </DropDownContainer>
                            </div>
                        </div>
                    </div>
                </Labels>
            </Filter>
            <Grid className="projects__grid">
            
            <Thumbnail2Cols projectsToRender={projectsToShow.slice(0, visible)} />

            <SeeMore className="see-more">
                <button className={visible >= projectsToShow.length ? `hidden` : `text text--title text--light-grey uppercase`} onClick={handleShowMoreProjects}>
                    <div className="load-more-section">&nbsp;</div>
                    <div className="load-more-section">Load More ↓</div>
                    <div className="load-more-section"  >&nbsp;</div>
                </button>
            </SeeMore>
            </Grid>
        </Content>

    )
}

const Filter = styled.div`
margin-bottom: 8px;
&.filter {
    padding: 5px 0;
    height: 250px;
    @media (min-width: 576px) {
        height: 125px;
     }
}

button { padding: 0; }
`;

const Labels = styled.div`
grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
grid-row-gap: 0.8em;
@media (min-width: 576px) {
    grid-template-rows: inherit;
 }
 @media screen and (max-width: 480px){
    grid-row-gap: 0.1em;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
 }

&.filter__labels {
    margin-top:10px;
    .label__reset {
        grid-column: col-start / span 1;
        grid-row: 1;

        .loop {
            transform: rotate(-270deg);
            font-size: 0.6875rem;
            border: .8px solid white;
            border-radius: 50px;
            padding: 4px;
            margin-top: 5px;

            &:hover {
                border: .8px solid black;
            }
        }
    }

    .labels {
        width: 100%;
        padding: 0;
    }

    .col-2 {
        grid-row: 4;
        @media (min-width: 576px) {
            grid-row: 1;
         }
         @media screen and (max-width: 480px){
            grid-row: 4;
         }
    }


    .col-3 {
        grid-row: 5;
        @media (min-width: 576px) {
            grid-row: 1;
         }
         @media screen and (max-width: 480px){
            grid-row: 5;
         }
    }


    .col-1__title {
        grid-column: col-start 2 / span 1;
        grid-row: 1;
        @media only screen and (min-width: 576px) and (max-width: 767.98px) {
            grid-column: col-start 2 / span 2;
        }
        @media (min-width: 768px) {
            grid-column: col-start 3 / span 1;
         }
    }
    .col-1__options {
        grid-row: 2;
        grid-column: col-start 2 / span 1;
        @media only screen and (min-width: 576px) and (max-width: 767.98px) {
            grid-column: col-start 2 / span 2;
        }
        @media (min-width: 768px) {
            grid-column: col-start 3 / span 2;
         }
    }

    .col-2__title {
        grid-column: col-start 1 / span 1;
        grid-row: 1;
        @media only screen and (min-width: 576px) and (max-width: 767.98px) {
            grid-column: col-start 5 / span 2;
            grid-row: 1;
        }
        @media (min-width: 768px) {
            grid-column: col-start 5 / span 1;
            grid-row: 1;
         }
    }
    .col-2__options {
        grid-column: col-start 1 / span 3;
        grid-row: 2;
        @media only screen and (min-width: 576px) and (max-width: 767.98px) {
            grid-column: col-start 5 / span 2;
            grid-row: 2;
        }
        @media (min-width: 768px) {
            grid-column: col-start 5 / span 2;
            grid-row: 2;
         }
    }

    .col-3__title {
        grid-column: col-start 1 / span 1;
        grid-row: 1;
        @media only screen and (min-width: 576px) and (max-width: 767.98px) {
            grid-column: col-start 7 / span 2;
            grid-row: 1;
        }
        @media (min-width: 768px) {
            grid-column: col-start 7 / span 1;
            grid-row: 1;
         }
    }
    .col-3__options {
        grid-column: col-start 1 / span 3;
        grid-row: 2;
        @media only screen and (min-width: 576px) and (max-width: 767.98px) {
            grid-column: col-start 7 / span 2;
            grid-row: 2;
        }
        @media (min-width: 768px) {
            grid-column: col-start 7 / span 2;
            grid-row: 2;
         }
    }

    .col-1__options,
    .col-2__options,
    .col-3__options { z-index: 1; }

    .col-2__options {
        z-index: 2;
        @media (min-width: 576px) {
            z-index: 1;
         }
    }
}

`;

const DropDownContainer = styled("div")`
  margin: 0 auto;
  position:relative;

`;

const DropDownHeader = styled("div")`
color: black;
border-bottom: .5px solid white;
`;

const DropDownListContainer = styled("div")`
position: absolute;
z-index: 100;
width: 104%;
max-height: 160px;
overflow-y: auto;
padding: 0px;
padding-left 4%;
right:0;
background: #E4E4E4;
padding-bottom: 20px;
border-radius: 0 0 4px 4px;
box-shadow: 0px 2px 2px 0px #ada6a6;
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`;

const DropDownList = styled("ul")`
grid-column: col-start 1 / span 2;
  padding: 0;
  margin: 0;
  color: black;

  li:hover{
    opacity:0.8;
  }
  &:first-child {
    padding-top: 0.8em;
  }
`;

const Grid = styled.div`
.cols-3__projects {
    display: grid;
    padding: 12px;
    gap: 4px;
    grid-template-columns: 1fr;
    @media only screen and (min-width: 576px) and (max-width: 767.98px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
    margin-bottom: 20px;
}
`;

const Content = styled.div``;
const SeeMore = styled.div`
.hidden { display: none; }
`;


export default FilterProjects


import React from 'react'
import styled from 'styled-components';
import TitleM from '../../../../../Texts/Titles/TitleM/TitleM';
import TitleS from '../../../../../Texts/Titles/TitleS/TitleS'
import LiveInfos from './LiveInfos'


const NewRelease = ({title, text, url, categorie, live, option}) => {

    return (    
        <Content className="section" style={{
          backgroundColor: `${option ? option.background_color : ""}`,
          color: `${option ? option.text_color : ""}`,
        }}>   
            <Section className="container">
                <TitleM title={title}/>
                <p className="text text--title release" dangerouslySetInnerHTML={{__html: text}}> 
                
                </p>
            </Section>   
            <LiveInfos category={categorie} live={live} option={option}/>
        </Content>
    )
}

const Content = styled.div``;
const Section = styled.div`
text-align: center;
.release { padding-top: 8px; }
p {
    grid-column: col-start 1 / span 4;
    @media only screen and (min-width: 576px) and (max-width: 767.98px) { 
        grid-column: col-start 2 / span 6;
    }
    @media (min-width: 768px) { 
        grid-column: col-start 2 / span 10;
    } 
}
`;


export default NewRelease


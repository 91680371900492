import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Content = styled.div`
color: black;
text-align: center;
span + span { padding-left: 5px; }
`;

const CurrentDate = () => {

    //var d = new Date();
    const[d, setD ] = useState(new Date());
    useEffect(() => {
        setInterval(() => setD(new Date()), 5000);
      }, []);
    let sign = (d.getTimezoneOffset() > 0) ? "-" : "+";
    let gmtHours = -d.getTimezoneOffset()/60;

    let signHours = (moment().hours() < 10 ) ? "0" : "";
    let signMinutes = (moment().minutes() < 10 ) ? "0" : "";


    return (
        <Content className="header__live col-2">
            <p className="live__today text text--subtitle">
                <span className="day">{moment().format("DD")}</span>
                <span className="month">{moment().format("MM")}</span>
                <span className="year">{moment().format("YY")}</span>
            </p>
            <p className="live__zone text text--subtitle">
                <span className="hour">{signHours}{moment().hours()}</span>
                <span className="minutes">{signMinutes}{moment().minutes()}</span>
                <span className="utc">UTC{sign == "+" && sign}{gmtHours}</span>
            </p>
        </Content>
    )
}

export default CurrentDate

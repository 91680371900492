import React from 'react';
import styled from 'styled-components';
import TitleS from '../../../../../Texts/Titles/TitleS/TitleS';

const Thumbnail2Cols = ({ projectsToRender, title }) => {

    return (
        <Content className="projects">
                {projectsToRender ? projectsToRender.map((project, index) => (
                    <a href={`/projects/${project.slug}`}>
                    <Project key={index} className="project">
                        <Title className="container-4">
                            <TitleS title={project.title} />
                        </Title>
                        <Height>
                        
                            <div className="project__img--square" style={{backgroundImage: `url(${project.featured_image.url})`}}>
                            {/* <LoadingPlaceHolder  extraStyles={{height: '100%', top: '0', left: '0',}} container /> */}
                            </div>
                            <div class="loading-text"></div>
                        </Height>
                        <Columns className="project__details container-4">
                        {project.client ?
                            <div className="col-1 container-4">
                                <h3 className="col-1__title text text--small">Client</h3>
                                <p className="col-1__text text text--small">{project.client.post_title}</p>
                            </div>
                            : ''}
                            {project.service ?
                            <div className="col-2 container-4">
                                <h3 className="col-2__title text text--small">Services</h3>
                                <ul className="col-2__text text text--small">
                                    {project.service ? project.service.map((service)=>(
                                        <li>{service.post_title}</li>
                                    )) : <li></li>}
                                </ul>
                            </div>
                            : ''}
                            {project.year ?
                            <div className="col-3 container-4">
                                <h3 className="col-3__title text text--small">Year</h3>
                                <p className="col-3__text text text--small">{project.year}</p>
                            </div>
                            : ''}
                        </Columns>
                    </Project>
                    </a>
                )): '' }
        </Content>
    )
}



const Content = styled.ul`
h3 {
    text-transform : uppercase;
}
a {
    margin-bottom : 40px;
}
display: grid;
padding: 0 12px;
gap: 4px;
grid-template-columns: 1fr;
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
    grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
}

margin-bottom: 20px;
`;

const Columns = styled.div`
margin-top: 8px;

.project__details li{
  line-height:0.81rem;
}

.col-1 {
    width: 100%;
    grid-row: 1;

    .col-1__title {
        grid-column: col-start 1 / span 1;
    }

    .col-1__text {
        grid-column: col-start 2 / span 2;
    }
}

.col-2 {
    width: 100%;
    grid-row: 2;

    .col-2__title {
        grid-column: col-start 1 / span 1;
    }

    .col-2__text {
        grid-column: col-start 2 / span 2;
    }
}

.col-3 {
    width: 100%;
    grid-row: 3;

    .col-3__title {
        grid-column: col-start 1 / span 1;
    }
    .col-3__text {
        grid-column: col-start 2 / span 2;
    }
}
`;


const Project = styled.div`
&.project {
    .title--s {
        padding: 0;
        text-align: center;
        margin-bottom: 5px;
    }
    .project__details{
        padding-left: 6px;
    }
    .project__details,
    .title {
        opacity: 1;
        transition: 0.3s;
    }

    &:hover {
    .project__details,
    .title {
        opacity: 0.25;
    }
    .project__img--square{
        transition: all 0.2s ease 0s;
        filter: invert(100%);
    }
    
}
`;

const Title = styled.div`
&.project__title {
    .col-1__title {
        text-align: center;
        grid-column: col-start 2/ span 2;
    }
}
`;

const Height = styled.div`
.project__img--square {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    

    &:before {
        content: "";
        display: inline-block;
        padding-bottom: 100%;
        vertical-align: top;
        height: 100%;
        width: 100%;
        transition: all 0.3s ease 0s;
        opacity: 0;
        background: #ffffff;
    }
}
`;


export default Thumbnail2Cols

import React, { Fragment } from 'react'
import MainContent from './components/MainContent/MainContent'
import styled from 'styled-components'


const Imprint = () => {
    return (
      <Fragment>
        <Content>
          <MainContent/>
        </Content>
      </Fragment>
    )
}

const Content = styled.main`
background: ${({ theme }) => theme.colors.lightGrey};
min-height: 100vh; 
padding-top: 12px;
`;

export default Imprint
